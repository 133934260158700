import {CartContext} from "./CartContext";
import {MassDataContext} from "./MassDataContext";
import {MessageContext} from "./MessageContext";
import {IanContext} from "./IanContext";
import {InputSpinnerContext} from "./InputSpinnerContext";
import {useEffect, useState} from "react";
import LocalStorage from "../utils/localStorage";
import {getCustomerCartAction} from "../data/actions/cart-action";

export function AppContextProvider(
    {children, getMassActionItems, setMassActionItems, ianList, setIanList}
) {
    const [successfullyAddedProductsToCart, setSuccessfullyAddedProductsToCart] = useState([]);
    const [successfullyAddedProductsToRfqCart, setSuccessfullyAddedProductsToRfqCart] = useState([]);
    const [successfullyAddedProductsToWishlist, setSuccessfullyAddedProductsToWishlist] = useState([]);

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [getQty, setQty] = useState({});

    const [customerCartId, setCustomerCartId] = useState(null);

    const fetchCustomerCart = async () => {
        if (!LocalStorage.checkIfIsLoggedIn()) {
            return;
        }
        let cart = await getCustomerCartAction();
        setCustomerCartId(cart.id);
    }

    useEffect(async () => {
        await fetchCustomerCart();
    }, []);

    return (
        <MessageContext.Provider value={{
            successMessage,
            errorMessage,
            setSuccessMessage,
            setErrorMessage
        }}>
            <CartContext.Provider value={customerCartId}>
                <InputSpinnerContext.Provider value={{getQty, setQty}}>
                    <MassDataContext.Provider value={{
                        getMassActionItems,
                        successfullyAddedProductsToCart,
                        setSuccessfullyAddedProductsToCart,
                        successfullyAddedProductsToRfqCart,
                        setSuccessfullyAddedProductsToRfqCart,
                        successfullyAddedProductsToWishlist,
                        setSuccessfullyAddedProductsToWishlist
                    }}>
                        <IanContext.Provider value={ianList}>
                            {children}
                        </IanContext.Provider>
                    </MassDataContext.Provider>
                </InputSpinnerContext.Provider>
            </CartContext.Provider>
        </MessageContext.Provider>
    );
}

