import React, {useContext, useEffect, useState} from 'react';
import FlyoutComponent from "./FlyoutComponent";
import { useFlyout } from './useFlyout';
import LocalStorage from "../../utils/localStorage";
import {CartContext} from "../../context/CartContext";
import {MassDataContext} from "../../context/MassDataContext";
import {addProductsToCartAction, getCustomerCartAction} from "../../data/actions/cart-action";
import {MessageContext} from "../../context/MessageContext";
import {__} from "../../i18n";

function AddToCartButton({product, qty = 0}) {
    const { isHovered, position, buttonRef, handleMouseEnter } = useFlyout();
    const [isAddToCartSuccessful, setAddToCartSuccessful] = useState(false);

    const carts = LocalStorage.getAllCarts();
    const cartId = useContext(CartContext);

    const {successfullyAddedProductsToCart} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    useEffect(() => {
        if (successfullyAddedProductsToCart?.includes(product.sku)) {
            setAddToCartSuccessful(true);
        }
    }, [successfullyAddedProductsToCart]);

    const handleAddToCart = async() => {

        // @todo check this way
        const bodyData = {
            "cartItems": [{
                "sku": product.sku,
                "qty": qty ? qty : product.reyher_ve,
                "quote_id": cartId
            }]
        };

        const response = await addProductsToCartAction(cartId, bodyData);

        response.forEach((el) => {
            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
                setAddToCartSuccessful(true);
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        })

        // todo check if we can extend add to cart api for fetch current items count
        let cart = await getCustomerCartAction();
        LocalStorage.setCartCount(cart.items_count);

        // @todo workaround for knockout stuff
        let dataReload = new CustomEvent("customer-data-reload-cart_resolver", {});
        document.dispatchEvent(dataReload);
    }

    return (
        <>
            {product ? (
                <button
                    className={"jsReactAddToCartButton button-reyher button-reyher--primary button-reyher--square" + (isAddToCartSuccessful ? " ajax-success" : "")}
                    title="Zum Warenkorb hinzufügen"
                    aria-label="Zum Warenkorb hinzufügen"
                    onClick={handleAddToCart}
                    onMouseEnter={handleMouseEnter}
                    ref={buttonRef}
                    data-qa={"add-to-cart-" + product.sku}
                >
                    <span className="button-reyher__link">
                        <span className="reyhericon icon-cart"></span>
                    </span>
                </button>
            ) : (
                <button type="button"
                        className="action tocart button-reyher button-reyher--primary button-reyher--square"
                        title={__('Add to cart not available')}
                        disabled>
                    <span className="reyhericon icon-cart"></span>
                </button>
            )}

            {isHovered && carts?.length > 1 && (
                <FlyoutComponent
                    position={position}
                    dropdownItems={carts}
                    header={__("choose cart")}
                    activeProp={"is_active"}
                    buttonRef={buttonRef}
                />
            )}

        </>
    );
}

export default AddToCartButton;
