import Request from "../../utils/request";

export async function createLabelService() {
    try {
        const BASE_URL = window.BASE_URL || window.location.origin;

        const apiUrl = BASE_URL + "etiketten-designer/printer/index";

        return await fetch(apiUrl, {
            method: "GET",
            headers: Request().getCustomHeaders(),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then((response) => {
                let container = window.jQuery(response).filter(".content").find(".container-reyher");
                /**
                 * create modal and add content
                 */
                let event = new CustomEvent("open-modal", {
                    "detail": {
                        title: null,
                        content: container[0]
                    }
                });
                document.dispatchEvent(event);

                return true;

            })
            .catch((error) => {
                console.error("Error:", error);
            });
    } catch (e) {
        console.error("Cart Service Error:", e);
        throw e;
    }
}
