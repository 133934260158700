const UrlParser = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const defaultPageSize = 25;
    const defaultPagination = 1;

    return {
        getDefaultUrlParams() {
            return urlParams;
        },

        getPagination() {
            return urlParams.get('p') === null
                ? defaultPagination
                : parseInt(urlParams.get('p'));
        },

        getProductListLimit() {
            return urlParams.get('product_list_limit') === null
                ? defaultPageSize
                : parseInt(urlParams.get('product_list_limit'));
        },

        //@todo own file
        getReyherNormText() {
            return urlParams.get('reyher_norm_text');
        },

        //@todo own file
        urlBuilder(
            attributes = null,
            inputValue= null,
            currentLimit = null,
            currentPage = null
        ) {
            let queryParams = null;

            if (attributes != null) {
                queryParams = Object.entries(attributes)
                    .map(([key, value]) => `attributes[]=${encodeURIComponent(key)}:${encodeURIComponent(value)}`)
                    .join('&');
            }
            //@todo own file
            let url = "http://localhost:3000/searchArray?q=" + inputValue;

            if (queryParams) {
                url = url + "&" + queryParams;
            }

            if (currentLimit) {
                url = url + "&product_list_limit=" + currentLimit;
            }

            if (currentPage) {
                url = url + "&p=" + currentPage;
            }

            return url;
        },

        urlHandling(currentLimit, currentPage) {
            if (!urlParams.has('product_list_limit') && currentLimit > 25) {
                urlParams.append("product_list_limit", currentLimit.toString());
                window.location.search = urlParams;
            } else if (urlParams.has('product_list_limit') && currentLimit > 25) {
                if (parseInt(urlParams.get("product_list_limit")) !== currentLimit) {
                    urlParams.set("product_list_limit", currentLimit.toString());
                    window.location.search = urlParams;
                }
            } else if (urlParams.has('product_list_limit') && currentLimit === 25) {
                urlParams.delete("product_list_limit")
                window.location.search = urlParams;
            }

            if (!urlParams.has('p') && currentPage > 1) {
                urlParams.append("p", currentPage.toString());
                window.location.search = urlParams;
            } else if (urlParams.has('p') && currentPage > 1) {
                if (parseInt(urlParams.get("p")) !== currentPage) {
                    urlParams.set("p", currentPage.toString());
                    window.location.search = urlParams;
                }
            } else if (urlParams.has('p') && currentPage === 1) {
                urlParams.delete("p")
                window.location.search = urlParams;
            }
        }
    }
}

export default UrlParser;

