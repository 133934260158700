import {useContext} from "react";
import {MessageContext} from "../../context/MessageContext";

const MessageBox = () => {
    const boxStyle = {
        "position": "fixed",
        "z-index": "1000",
        "opacity": "0.94",
        "height": "0px",
        "width": "100%",
        "left": "0px",
        "right": "0px",
        "margin": "0px auto",
        "display": "block",
    };

    const {
        errorMessage,
        successMessage,
        setErrorMessage,
        setSuccessMessage
    } = useContext(MessageContext);

    const handleCloseButton = () => {
        setErrorMessage(null);
        setSuccessMessage(null);
    }

    return (successMessage || errorMessage) && (
        <div className={"page messages"} style={boxStyle}>
            <div className={`${successMessage || errorMessage ? 'show' : 'hidden'}`} role="alert">
                <div className={`messagebox messagebox--${successMessage ? 'success' : 'error'}`}>
                    <span aria-hidden="true" className={`circle-icons__${successMessage ? 'success' : 'error'}`}></span>
                    <div className="messagebox__description">
                        <span className="text" >{successMessage || errorMessage}</span>
                    </div>

                    <a onClick={() => handleCloseButton()}
                       className="messagebox__close reyhericon icon-close"></a>
                </div>
            </div>
        </div>
    );
}
export default MessageBox;
