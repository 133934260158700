import AddToCartButton from "../../components/button/AddToCartButton";
import RfqButton from "../../components/button/RfqButton";
import CadButton from "../../components/button/CadButton";
import {useState} from "react";
import AddToWishlistButton from "../../components/button/AddToWishlistButton";

const RioScanRow = ({product}) => {
    const [getQty, setQty] = useState(false);
    const reyher_cad_table = typeof product != "undefined" && product.hasOwnProperty("reyher_cad_table") ? product.reyher_cad_table : false;

    return (
        <>
            <AddToCartButton product={product} qty={getQty}/>
            <RfqButton product={product}/>
            <CadButton product={product} isEnabled={!!reyher_cad_table} />
            <AddToWishlistButton product={product} />
        </>
    );
}

export default RioScanRow;
