import Request from "../../utils/request";

export async function preOrderCadService(bodyData) {
    try {
        const BASE_URL = window.location.origin || window.BASE_URL;

        const apiUrl = BASE_URL + "/rest/V1/cadenas/mine";

        return await fetch(apiUrl, {
            method: "POST",
            headers: Request().getCustomHeaders(),
            body: JSON.stringify(bodyData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    } catch (e) {
        console.error("Cart Service Error:", e);
        throw e;
    }
}
