import DropdownItem from "../dropdown/DropdownItem";
import React, {useContext, useState} from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import {__} from "../../i18n";
import {ToolbarContext} from "../../context/ToolbarContext";

const Amount = ({currentLimit}) => {

    const {limit, pageSize, setPageSize} = useContext(ToolbarContext);

    //@todo use in default fallback configuration
    const availableLimit =  [25, 50, 100];

    const [isOpen, setIsOpen] = useState(false);

    const handleItemClick = (itemName) => {
        currentLimit(itemName);
        setPageSize(itemName);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = () => {
        setIsOpen(false);
    }

    const dropDownRef = useOutsideClick(handleClickOutside);

    return (
        <div className="productlist_toolbar_amount">
            <div className={`dropdown-reyher ${isOpen ? 'dropdown-reyher--open' : ''}`}
                 ref={dropDownRef}
            >
                <a className={`dropdown-reyher__toggle ${pageSize !== limit ? 'dropdown-reyher__toggle--active' : ''}`}
                   onClick={toggleDropdown}
                   data-value="default">
                    {__('%1 per page', pageSize)}
                </a>
                <ul className="dropdown-reyher__list dropdown-reyher__content">
                    {availableLimit.map((item, index) => (
                        <DropdownItem
                            key={index}
                            name={item}
                            href={item}
                            action={item}
                            permission={true}
                            onItemClick={handleItemClick}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Amount;
