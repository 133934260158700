import CadButton from "../../../components/button/CadButton";
import AddToWishlistButton from "../../../components/button/AddToWishlistButton";
import AddToCartButton from "../../../components/button/AddToCartButton";
import RfqButton from "../../../components/button/RfqButton";
import {useState} from "react";
import Ian from "../../../components/ian/Ian";
import {__} from "../../../i18n";
import InputSpinnerNew from "../../../components/InputSpinnerNew";
import {openDetailLayerAction} from "../../../data/actions/detail-layer-action";

const OfferCartRow = ({index, id, offerItem, onChange}) => {
    const [getQty, setQty] = useState(0);
    const [isChecked, setIsChecked] = useState(true);

    const saleableOfferItem = offerItem && offerItem.is_offer_item_salable ? offerItem : false;

    const checkHandler = (index, qty) => {
        console.info("click checkbox", index, qty);

        // is qty not changed, then use ve
        if (typeof qty === "undefined") {
            setIsChecked(!isChecked);
        } else {
            setIsChecked(true);
        }

        setQty(qty)
        onChange(index, qty)
    }

    const openDetailLayer = async (event) => {
        await openDetailLayerAction(offerItem, event)
    }

    return (
        <>
            <tr className={index % 2 ? "even" : "odd"}>
                <td>
                    <div className="form-checkbox-set">
                        <input id={'cb-' + offerItem.sku}
                               type="checkbox"
                               name={'cb-' + offerItem.sku}
                               value="0"
                               className="form-checkbox jsReactCheckBox"
                               data-product-sku={offerItem.sku}
                               checked={isChecked}
                               onChange={() => checkHandler(index, "undefined")}
                        />
                        <label htmlFor={'cb-' + offerItem.sku}>
                            <span></span>
                        </label>
                    </div>
                </td>
                <td>
                    {!offerItem.has_fake_product_data ? (
                        <a className="productlist_item_anchor js-Modal"
                           onClick={(ev) => openDetailLayer(ev)}
                           data-title={offerItem.name}>
                            {offerItem.is_fake_product && offerItem.orig_sku ? (
                                offerItem.orig_sku
                            ) : (
                                offerItem.sku
                            )}
                        </a>
                    ) : (
                        offerItem.is_fake_product && offerItem.orig_sku ? (
                            offerItem.orig_sku
                        ) : (
                            offerItem.sku
                        )
                    )}
                    <br/>
                    {
                        offerItem.is_fake_product && offerItem.orig_name ? (
                            offerItem.orig_name
                        ) : (
                            offerItem.name
                        )
                    }
                    <br/>
                    {
                        !offerItem.is_fake_product && offerItem.positiontext_material
                    }
                </td>
                <td className="text-align-right">
                    {offerItem.ve}
                </td>
                <td className="offer-table_quanity">
                    <InputSpinnerNew
                        index={index}
                        id={'sp-' + offerItem.sku}
                        productId={id}
                        ve={offerItem.ve}
                        defaultInput={offerItem.ve}
                        triggerOnChange={(qty) => checkHandler(index, qty)}
                        dawnAttribute={offerItem.product.reyher_kein_anbruch}
                    />
                </td>
                <td className="text-align-right">
                    {offerItem.offer_price}
                    <br/>
                    {offerItem.offer_list_price}
                </td>
                <td className="text-align-right">
                    {offerItem.row_weight}
                </td>
                <td>
                    <Ian product={offerItem} useInputField={true}/>
                </td>
                <td className="offer-table_quanity_status">
                    <div className={`circle-icons ${offerItem.offer_item_status_class} d-none d-lg-block`}
                         title={offerItem.offer_item_status}
                    >
                            <span aria-hidden="true"
                                  className={`circle-icons__${offerItem.offer_item_status_class}`}>
                            </span>
                    </div>
                </td>

                <td className="text-align-right d-none d-lg-block">
                    <AddToCartButton product={saleableOfferItem} qty={getQty} />
                    <RfqButton product={offerItem} qty={getQty} />
                    <CadButton product={offerItem} isEnabled={!!offerItem.reyher_cad_table}/>
                    <AddToWishlistButton product={offerItem} qty={getQty}/>
                </td>
            </tr>
            <tr className={` ${index % 2 ? "even" : "odd"} additional`}>
                <td>&nbsp;</td>
                <td className="d-none d-md-block d-lg-none">
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Quantity/VE')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.ve}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Positionnumber')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.sap_positionnumber}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Total')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.offer_price}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Price/100')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.offer_list_price}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Status')}
                        </div>
                        <div className="productlist_table-column-value">
                            <div className={`circle-icons ${offerItem.offer_item_status_class}`}
                                 title={offerItem.offer_item_status}>
                                <span aria-hidden="true"
                                      className={`circle-icons__${offerItem.offer_item_status_class}`}>
                                </span>
                            </div>
                        </div>

                    </div>
                </td>
                <td>&nbsp;</td>
                <td className="d-md-none">
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Quantity/VE')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.ve}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Positionnumber')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.sap_positionnumber}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Total')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.offer_price}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Price/100')}
                        </div>
                        <div className="productlist_table-column-value">
                            {offerItem.offer_list_price}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Status')}
                        </div>
                        <div className={`circle-icons ${offerItem.offer_item_status_class}`}
                             title={offerItem.offer_item_status}>
                                <span aria-hidden="true"
                                      className={`circle-icons__${offerItem.offer_item_status_class}`}>
                                </span>
                        </div>
                    </div>
                </td>
                <td className="text-align-right">
                    {offerItem.sap_positionnumber}
                </td>
                <td dangerouslySetInnerHTML={{__html: offerItem.delivery_message}}></td>
                <td>&nbsp;</td>
                <td colSpan="4" className="text-align-right">
                    {offerItem.has_item_position_condition &&
                        <div>
                            {__('goods value')}
                            {offerItem.goods_value}
                        </div>
                    }

                    {offerItem.has_discount_amount &&
                        <div>
                            {offerItem.discount_amount}
                        </div>
                    }

                    {offerItem.has_item_position_condition &&
                        <div>
                            {__('Net price')}
                            {offerItem.net_price}
                        </div>
                    }
                </td>
                <td className="text-align-left d-lg-none margin-top-10 cartlist_table-buttons">
                    <div className="d-inline">
                        <AddToCartButton product={saleableOfferItem} qty={getQty}/>
                        <RfqButton product={offerItem} qty={getQty} />
                        <CadButton product={offerItem} isEnabled={!!offerItem.reyher_cad_table}/>
                        <AddToWishlistButton product={offerItem} qty={getQty} />
                    </div>
                </td>
            </tr>
        </>
    );
}

export default OfferCartRow;
