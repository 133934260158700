import React from 'react';
import MassActionsNew from "../../../../components/toolbar/MassActionsNew";
import {__} from "../../../../i18n";
import {useAddToCart} from "../../../../hooks/useAddToCart";
import {useAddToRfqCart} from "../../../../hooks/useAddToRfqCart";
import {useRequestCad} from "../../../../hooks/useRequestCad";
import {useRemoveFromWishlist} from "../../../../hooks/useRemoveFromWishlist";
import {useUpdateItemQuantityInWishlist} from "../../../../hooks/useUpdateItemQuantityInWishlist";
import {useMoveItemsToWishlist} from "../../../../hooks/useMoveItemsToWishlist";
import LocalStorage from "../../../../utils/localStorage";
import {useAddToWishlist} from "../../../../hooks/useAddToWishlist";
import {createLabelAction} from "../../../../data/actions/create-label-action";

const Toolbar = ({hasItemsOfExpiredOffers}) => {

    const addToCart = useAddToCart();
    const addToRfqCart = useAddToRfqCart();
    const addToWishlist = useAddToWishlist();
    const requestCad = useRequestCad();
    const removeFromWishlist = useRemoveFromWishlist();
    const updateItemQuantityInWishlist = useUpdateItemQuantityInWishlist();
    const moveItemsToWishlist = useMoveItemsToWishlist();

    const wishlists = LocalStorage.getWishlists();
    const carts = LocalStorage.getAllCarts();

    const hasMultipleWishlists = wishlists?.length > 1
    const hasMultipleCarts = carts?.length > 1;

    const availableActions = [
        {id:1, value: "addToRfqCart", label: __("add to request cart"), onClick: () => addToRfqCart()},
        {id:2, value: "addToWishlist", label: __("add to wishlist"), onClick: () => addToWishlist(), hasHover: hasMultipleWishlists, hoverOptions: wishlists, flyoutHeader: __("Favourite list")},
        {id:3, value: "requestCad", label: __("request cad"), onClick: () => requestCad()},
        {id:4, value: "createLabel", label: __("create label"), onClick: () => createLabelAction()},

        {id:5, value: "exportCsv", label: __("Export as CSV"), onClick: () => removeFromWishlist()},
        {id:6, value: "exportXlsx", label: __("Export as XLSX"), onClick: () => updateItemQuantityInWishlist()},
        {id:7, value: "exportPdf", label: __("Export as PDF"), onClick: () => moveItemsToWishlist()},
    ];

    if (!hasItemsOfExpiredOffers) {
        availableActions.push({id:0, value: "addToCart", label: __("add to cart"), onClick: () => addToCart(), hasHover: hasMultipleCarts, hoverOptions: carts, flyoutHeader: __("choose cart")});
    }

    return (
        <MassActionsNew options={availableActions}/>
    );
}

export default Toolbar;
