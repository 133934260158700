import {IanContext} from "../../context/IanContext";
import {useContext, useEffect, useState} from "react";
import {ianDeleteAction, ianSaveAction} from "../../data/actions/ian-action";
import LocalStorage from "../../utils/localStorage";

const Ian = ({product, useInputField = true ,}) => {

    const [getIan, setIan] = useState(null);
    const [getDefaultIan, setDefaultIan] = useState(null);

    const [isSuccessfullySaved, setSuccessfullySaved] = useState(false);
    const [delayDuration, setDelayDuration] = useState(3000);

    const ianList = useContext(IanContext);

    useEffect(() => {
        if (ianList && ianList[0].hasOwnProperty(product.sku)) {
            // set initial both ians to the same value to detect changes in value
            setDefaultIan(ianList[0][product.sku]);
            setIan(ianList[0][product.sku]);
        }
    }, [ianList]);

    const actionHandler = async (event) => {

        // detect if ian is not changed
        if (getDefaultIan === getIan) {
            return;
        }

        //check if enter/tab/or left input is detected
        if (event.type === "blur" || event.keyCode === 13 || event.keyCode === 9) {

            const bodyData = {
                "ianData": {
                    "productSku": product.sku,
                    "customSku": getIan
                }
            };

            if (getIan) {
                await saveHandler(bodyData);
            } else {
                await deleteHandler(bodyData);
            }

            setSuccessfullySaved(true);

            //set default ian to changed ian
            setDefaultIan(getIan);

            setTimeout(() => {
                // @todo check if is valid response
                setSuccessfullySaved(false);
            }, delayDuration);
        }
    }

    const saveHandler = async (bodyData) => {
        const response = await ianSaveAction(bodyData);
    }

    const deleteHandler = async (bodyData) => {
        const response = await ianDeleteAction(bodyData, product.sku);
    }

    /**
     * user can edit ian
     *
     * @returns {*}
     */
    const hasIanEditPermission = () => {
        return LocalStorage.getArticleCustomSkuEditPermission();
    }

    /**
     * user don´t can edit ian
     * user can see ian
     *
     * @returns {false|*}
     */
    const hasIanPermission = () => {
        return !LocalStorage.getArticleCustomSkuEditPermission() && LocalStorage.getArticleCustomSkuPermission();
    }

    return (
        <>
            { hasIanEditPermission && useInputField? (
                <>
                    <label htmlFor={'ian-' + product.sku}></label>
                    <input className="form-input-reyher ian productlist-ian"
                       type="text"
                       id={'ian-' + product.sku}
                       data-sku={product.sku}
                       defaultValue={getDefaultIan}
                       value={getIan}
                       onBlur={e => actionHandler(e)}
                       onKeyUp={e => actionHandler(e)}
                       onChange={e => setIan(e.target.value)}
                    />
                    <span aria-hidden="true" className={`circle-icons__available ${!isSuccessfullySaved ? 'hidden' : ''}`}></span>
                </>
            ) : (hasIanPermission ? (
                <span className="ian jsIan"
                      data-sku={product.sku}>
                    {getIan}
                </span>
                ) : null
            )}
        </>
    );
}

export default Ian;
