import LocalStorage from "../../utils/localStorage";
import {useState} from "react";
function NavigationMiniCart() {
    const [cartSummaryState, setCartSummaryState] = useState(LocalStorage.getCartSummaryCount());
    const [cartPermissionState, setCartPermissionState] = useState(LocalStorage.getCartPermission());

    document.addEventListener("customer-data-reload-cart_resolver", (event) => {
        setCartSummaryState(LocalStorage.getCartSummaryCount());
        setCartPermissionState(LocalStorage.getCartPermission());
    });

    const [rfqCartCount, setRfqCartCount] = useState(LocalStorage.getRfqCartCount());
    const [rfqPermissionState, setRfqPermissionState] = useState(LocalStorage.getCartRequestPermission());

    document.addEventListener("customer-data-reload-rfq_resolver", (event) => {
        setRfqCartCount(LocalStorage.getRfqCartCount());
        setRfqPermissionState(LocalStorage.getCartRequestPermission());
    });

    return (
        <>
            <span className="rfq-cart-bubble">
                {rfqPermissionState && (
                <span>
                    <span className="navbar-info__counter"
                          data-count={rfqCartCount}>
                       <a href="/rfq/quote/cart/" className="navbar-info__link--message"></a>
                    </span>
                </span>
                )}
            </span>
            <div className="minicart-wrapper">
                {cartPermissionState && (
                <span className="navbar-info__counter" data-count={cartSummaryState}>
                    <a href="/checkout/cart/" className="navbar-info__link--cart"></a>
                </span>
                )}
            </div>
            <span className="navbar-info__search d-lg-none"></span>
        </>
    );
}

export default NavigationMiniCart;
