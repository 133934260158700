import {__} from "../i18n";
import {removeItemsFromWishlistAction} from "../data/actions/wishlist-action";
import {useContext} from "react";
import {MassDataContext} from "../context/MassDataContext";
import {MessageContext} from "../context/MessageContext";

export const useRemoveFromWishlist = () => {
    const {getMassActionItems} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    const BASE_URL = window.location.origin || window.BASE_URL;

    return async() => {
        console.log("call wishlist remove");

        const bodyData = {
            wishlistItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    item_id: item._id
                }))
        };

        if (!bodyData.wishlistItems.length) {
            setErrorMessage(__("no products selected"));
            return;
        }

        const response = await removeItemsFromWishlistAction(bodyData);

        response.forEach((el) => {
            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }

            if (el?.success === true && el?.redirect) {
                window.location = BASE_URL + "/" + el.redirect;
            }
        })
    }
}
