import React, {useState, useEffect} from 'react';
import ProductRow from "./ProductRow";
import Toolbar from "../../components/toolbar/Toolbar";
import UrlParsing from "../../utils/urlParsing";
import {__} from "../../i18n";
import MessageBox from "../../components/messages/MessageBox";
import {ianBulkAction} from "../../data/actions/ian-action";
import {AppContextProvider} from "../../context/AppContextProvider";
import LocalStorage from "../../utils/localStorage";
import Loader from "../../components/loader/Loader";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ProductList = ({defaultProductList}) => {

    // State to store the fetched data
    const [hits, setHits] = useState([]);
    const [totals, setTotals] = useState(0);

    // State to track loading status
    const [isLoading, setLoading] = useState(true);
    // State to track any errors
    const [error, setError] = useState(null);

    const [currentLimit, setCurrentLimit] = useState(UrlParsing().getProductListLimit());
    const [currentPage, setCurrentPage] = useState(UrlParsing().getPagination());

    const [getMassActionItems, setMassActionItems] = useState([]);
    const [ianList, setIanList] = useState(null);

    const fetchDefaultData = async (attributes = null) => {
        try {
            // load default List here
            let result = defaultProductList;

            // handling amount and pagination here, reload page
            UrlParsing().urlHandling(currentLimit, currentPage);

            await setData(result);

        } catch (error) {
            // Handle any errorss
            setError(error.message);
        }
    };

    const setData = async (result) => {
        setHits(result.hits?.hits);

        setTotals(result.hits?.total?.value);

        setMassActionItems(result.hits?.hits.map(product => (
            {
                _id: product._id,
                sku: product._source.sku,
                qty: product._source.reyher_ve,
                checked: false
            }
        )));
    }

    const fetchIan = async () => {

        if (!LocalStorage.checkIfIsLoggedIn()) {
            return;
        }

        const skuList = getMassActionItems.map((item) => {
            return item.sku
        });

        if (skuList.length) {
            const bodyData = {"skus": skuList}

            const response = await ianBulkAction(bodyData);
            setIanList(response);
        }
    }

    useEffect(() => {

        fetchDefaultData().then(() => (setLoading(false)));

        document.addEventListener("legacy-application-ready", () => {
            console.log("legacy-application-ready");

            if (typeof window.TB !== 'undefined') {
                window.TB.findModules(window.jQuery(".js-Modal").parent().get(0));
            }
        })


    }, [currentLimit, currentPage]);

    useEffect(() => {
        fetchIan();
    }, [getMassActionItems]);

    function getCurrentLimit(limit) {
        setCurrentLimit(limit)
    }

    function getCurrentPage(currentPage) {
        setCurrentPage(currentPage)
    }

    const handleOnChange = (index, qty) => {
        let updatedCheckedState = getMassActionItems;

        updatedCheckedState.forEach((product, idx) => {
            if (idx === parseInt(index)) {
                if (typeof qty === "undefined") {
                    product.checked = !product.checked;
                } else {
                    product.qty = qty;
                    product.checked = true;
                }
            }
        });

        setMassActionItems(updatedCheckedState);
    }

    const toggleAllCheckboxes = () => {
        let updatedCheckedState = getMassActionItems;

        updatedCheckedState.forEach((product, idx) => {
            product.checked = true;
        });

        setMassActionItems(updatedCheckedState);
    }

    return (
        <>
            {isLoading && <Loader />}

            {<AppContextProvider
                                getMassActionItems={getMassActionItems}
                                setMassActionItems={setMassActionItems}
                                ianList={ianList}
                                setIanList={setIanList}>

                <p data-qa="search-result-totals">{__("%1 items were found using the following search criteria", totals)}</p>

                { totals &&
                    <Toolbar totals={totals}
                             currentLimit={getCurrentLimit}
                             currentPage={getCurrentPage} />
                }

                <div className="table-container productlist_table-container">
                    <div className="form-input-set">
                        <table className="table productlist_table">
                            <thead>
                            <tr>
                                <th scope="col">
                                    <div className="form-checkbox-set">
                                        <input id="cbr0" type="checkbox" name="cbr0" value="0"
                                               className="form-checkbox"
                                               onChange={() => toggleAllCheckboxes()}
                                        />
                                        <label form="cbr0" />
                                    </div>
                                </th>
                                <th scope="col">
                                    {__('articlenumber')}
                                    <br/>
                                    {__('description')}
                                </th>
                                <th scope="col">
                                    {__('qty/ve')}
                                    <br/>

                                    {
                                        (LocalStorage.getArticleCheckPrice() || LocalStorage.getArticleListPrice())
                                        &&
                                        __('catalogprice/100')
                                    }
                                </th>
                                <th scope="col">
                                    {
                                        (LocalStorage.getCartRequestPermission() || LocalStorage.getArticleCheckAvailabilityPermission())
                                        &&
                                        __('qty')
                                    }
                                </th>
                                <th scope="col">
                                    {
                                        LocalStorage.getArticleCustomSkuPermission() &&
                                        __('custom articlenumber')
                                    }
                                </th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                                <tbody>
                                    {hits.map((product, index) => (
                                        <ProductRow
                                            index={index}
                                            id={product._id}
                                            product={product._source}
                                            onChange={handleOnChange}
                                        />
                                    ))}
                                </tbody>
                        </table>
                    </div>
                </div>
                <MessageBox/>
            </AppContextProvider>}
        </>
    );
}

export default ProductList;
