import React, {useContext, useEffect, useState} from "react";
import UrlParsing from "../../utils/urlParsing";
import {ToolbarContext} from "../../context/ToolbarContext";
import {__} from "../../i18n";
import Loader from "../loader/Loader";

const Pagination = ({totals, currentPage}) => {
    const p = UrlParsing().getPagination();

    const {pageSize} = useContext(ToolbarContext);

    const [pagination, setPagination] = useState(p);
    const [lastPageSize, setLastPageSize] = useState(1);

    // jump is set default to 0
    const [jump, setJump] = useState(0);

    // frameLength is set default to 2
    const [frameLength, setFrameLength] = useState(2);

    const [frameStart, setFrameStart] = useState(0);
    const [frameEnd, setFrameEnd] = useState(0);

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        determineLastPageSize();
        initFrame();
    }, [lastPageSize, pageSize, pagination]);

    const determineLastPageSize = () => {
        setLastPageSize(1);
        if (pageSize) {
            setLastPageSize(Math.ceil(totals / pageSize));
        }
    }

    const getCurPage = () => {
        return pagination;
    }

    const initFrame = () => {
        let start = 0;
        let end = 0;

        if (getLastPageNum() <= frameLength) {
            start = 1;
            end = getLastPageNum();
        } else {
            let half = Math.ceil(frameLength / 2);
            if (getCurPage() >= half &&
                getCurPage() <= getLastPageNum() - half
            ) {
                start = getCurPage() - half + 1;
                end = start + frameLength - 1;
            } else if (getCurPage() < half) {
                start = 1;
                end = frameLength;
            } else if (getCurPage() > getLastPageNum() - half) {
                start = getLastPageNum();
                end = end - frameLength + 1;
            }
        }

        setFrameStart(start);
        setFrameEnd(end);
    }

    const canShowFirst = () => {
        return jump > 0;
    }

    const isPageCurrent = (page) => {
        return parseInt(page) === pagination;
    }

    const isLastPage = () => {
        return getCurPage() >= lastPageSize;
    }

    const getFramePages = () => {
        return [getFrameStart(), getFrameEnd()]
    }

    const getFrameStart = () => {
        return frameStart;
    }
    const getFrameEnd = () => {
        return frameEnd;
    }

    const isFirstPage = () => {
        return getCurPage() === 1;
    }

    const getLastPageNum = () => {
        return lastPageSize;
    }

    const handlePaginationClick = (page) => {
        setLoading(true);
        setPagination(page);
        currentPage(page);
    }

    return (
        <>
            {isLoading && <Loader />}

            {getLastPageNum() > 1 &&
            <div className="productlist_toolbar_page">
                <nav className="pagination pagination--center">
                    <ul className="pagination__list" aria-labelledby="paging-label">

                        {isFirstPage() === false &&
                            <li className="pagination__item pagination__prev">
                                <a onClick={() => handlePaginationClick(pagination - 1)}
                                   className="pagination__link">
                                    <span className="sr-only"></span>
                                    <span className="reyhericon icon-navigation-left"></span>
                                </a>
                            </li>
                        }

                        {canShowFirst === true && isPageCurrent(1) === true &&
                            <li className="pagination__item">
                                <strong className="page">
                                    <span>1</span>
                                </strong>
                            </li>
                        }

                        {isPageCurrent(1) === true ? (
                            <li className="pagination__item active">
                                <strong className="page">
                                    <span>1</span>
                                </strong>
                            </li>
                        ) : (
                            <li className="pagination__item">
                                <a className="pagination__link" onClick={() => handlePaginationClick(1)}>
                                    <span>1</span>
                                </a>
                            </li>
                        )}

                        {isPageCurrent(1) === false && isPageCurrent(2) === false &&
                            <li className="pagination__item pagination__item--ellipsis">
                                <span className="pagination__link">...</span>
                            </li>
                        }

                        {getFramePages().map((page) =>
                                page > 1 && page < getLastPageNum() && (
                                    isPageCurrent(page) === true ? (
                                        <li className="pagination__item active">
                                            <strong className="page">
                                                <span>{page}</span>
                                            </strong>
                                        </li>
                                    ) : (
                                        <li className="pagination__item">
                                            <a onClick={() => handlePaginationClick(page)}
                                               className="pagination__link">
                                                <span>{page}</span>
                                            </a>
                                        </li>
                                    )
                                )
                        )}

                        {isPageCurrent(getLastPageNum()) === false &&
                            isPageCurrent(getLastPageNum() - 1) === false &&
                            isPageCurrent(getLastPageNum() - 2) === false && (
                                <li className="pagination__item pagination__item--ellipsis">
                                    <span className="pagination__link">...</span>
                                </li>
                            )}

                        {getLastPageNum() > 1 && (
                            isPageCurrent(getLastPageNum()) === true ? (
                                <li className="pagination__item active">
                                    <strong className="page">
                                        <span>{getLastPageNum()}</span>
                                    </strong>
                                </li>
                            ) : (
                                <li className="pagination__item">
                                    <a className="pagination__link"
                                       onClick={() => handlePaginationClick(getLastPageNum())}>
                                        <span>{getLastPageNum()}</span>
                                    </a>
                                </li>
                            )
                        )}

                        {isLastPage() === false && (
                            <li className="pagination__item pagination__next">
                                <a className="action  pagination__link"
                                   onClick={() => handlePaginationClick(pagination + 1)}
                                   title="Weiter">
                                    <span className="sr-only"> {__('Next')}</span>
                                    <span className="reyhericon icon-navigation-right"></span>
                                </a>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
            }
        </>
    );
}

export default Pagination;
