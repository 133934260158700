import {useEffect, useState} from "react";
import {__} from "../i18n";
import LocalStorage from "../utils/localStorage";

/**
 * @todo check
 * jsAnbruch = dawn

 */
const InputSpinnerNew = ({
    index,
    id,
    itemId,
    triggerOnChange,
    dawnAttribute,
    ve,
    defaultInput
}) => {

    //set ve from product attribute here
    const dataVe = Number(ve);
    const dataMinValue = dataVe;
    const dataMaxValue = dataVe * 10000;
    //set default initial value here, it could be x * ve
    const [inputValue, setInputValue] = useState(Number(defaultInput));

    const [isDecreaseButtonDisabled, setIsDecreaseButtonDisabled] = useState(true);
    const [isIncreaseButtonDisabled, setIsIncreaseButtonDisabled] = useState(false);
    const [isModalOpen, setIsOpen] = useState(false);

    const numberOfPiecesPermission = LocalStorage.getNumberOfPiecesPermission();
    const dawn = !!dawnAttribute;

    const [allowSingleItemQty, setAllowSingleItemQty] = useState(!!(numberOfPiecesPermission || dawn));
    const [minAllowedValue, setMinAllowedValue] = useState(Number(!!(numberOfPiecesPermission || dawn)));

    const handleDecreasingClick = () => {
        const newValue = ((inputValue - dataVe) % dataMinValue)
            ? Number(Math.floor(inputValue/dataVe) * dataMinValue)
            : Number(inputValue - dataVe);

        setInputValue(newValue);
        triggerOnChange(newValue);
    }

    const handleIncreasingClick = () => {
        const newValue =  ((inputValue + dataVe) % dataMinValue)
            ? Number(Math.floor(inputValue/dataVe) * dataMinValue + dataVe)
            : Number(inputValue + dataVe);

        setInputValue(newValue);
        triggerOnChange(newValue);
    }

    function handleOnChange(event) {
        //setCurrentElement(event.target);
        const newValue = Number(event.target.value.trim().replace(/^0+/, ""));

        setInputValue(newValue);
        triggerOnChange(newValue);
    }

    function handleCloseClick() {
        document.querySelector('.panel.wrapper').style.zIndex = null;
        setIsOpen(false);
    }

    const handleOnBlur = (event) => {
        const isAlreadyModalOpen
            = document.querySelectorAll('.inp-spinner__quantity-container--active').length

        let currentValue = Number(inputValue);

        if (currentValue === 0) {
            setInputValue(Number(dataMinValue));
        }

        if (currentValue < dataMinValue) {
            setInputValue(Number(dataMinValue));
        }

        if (
            !allowSingleItemQty
            && isAlreadyModalOpen === 0
            && (inputValue % dataVe !== 0)
        ) {
            document.querySelector('.panel.wrapper').style.zIndex = "1";
            setIsOpen(true);
        }

        if (allowSingleItemQty) {
            if (event.target.value === "") {
                setInputValue(dataVe);
            } else {
                setInputValue(Number(event.target.value));
            }
        }
    }

    const updateList = () => {
        //triggerOnChange(inputValue);
    }

    //@todo couldbe deprecated
    const updateButtons = () => {

    }

    //@todo couldbe deprecated
    const setButtonValue = () => {

    }

    useEffect(() => {
        // Use the updated state value here
        updateButtons();
        checkMinValue();
        checkMaxValue();
        setButtonValue();
        updateList();

    }, [inputValue]);

    const checkMinValue = () => {
        let currentValue = Number(inputValue);
        if (currentValue <= dataMinValue) {
            setIsDecreaseButtonDisabled(true);
        } else {
            setIsDecreaseButtonDisabled(false);
        }
    }

    const checkMaxValue = () => {
        //sets user input to maxValue if its above
        if (inputValue > dataMaxValue) {
            setInputValue(Number(dataMaxValue));
        }

        let currentValue = Number(inputValue);
        if (dataMaxValue && currentValue === dataMaxValue) {
            setIsIncreaseButtonDisabled(true);
        }

        if (inputValue < dataMaxValue) {
            setIsIncreaseButtonDisabled(false);
        }
    }

    const handleQtyBackdrop = (event, buttonValue) => {
        event.preventDefault();

        const newValue = Number(buttonValue * dataVe);

        setInputValue(newValue);
        triggerOnChange(newValue);

        document.querySelector('.panel.wrapper').style.zIndex = null;
        setIsOpen(false);

        //setButtonValue();
        //updateButtons();
    }

    return (
        (LocalStorage.getArticleCheckAvailabilityPermission() || LocalStorage.getCartRequestPermission()) &&
        <div className={`jsReactInputSpinner inp-spinner`}
             id={id}
             data-ve={dataVe}
             data-product-id={'sp-' + id}
             data-item-id={itemId}>
            <button type="button"
                    onClick={handleDecreasingClick}
                    disabled={isDecreaseButtonDisabled}
                    className={`inp-spinner__button ${isDecreaseButtonDisabled ? 'inp-spinner__button--disabled' : ''} inp-spinner__decrease`}>-</button>
            <input type="number"
                   name="qty"
                   data-field-name="qty"
                   className="inp-spinner__input"
                   data-steps={dataVe}
                   data-id={itemId ? itemId : undefined}
                   data-qty={inputValue}
                   data-minvalue={dataMinValue}
                   data-maxvalue={dataMaxValue}
                   value={inputValue}
                   data-role={itemId ? "cart-item-qty" : undefined}
                   pattern="[0-9]+"
                   onChange={handleOnChange}
                   onBlur={handleOnBlur}
            />
            <button type="button"
                    onClick={handleIncreasingClick}
                    disabled={isIncreaseButtonDisabled}
                    className={`inp-spinner__button ${isIncreaseButtonDisabled ? 'inp-spinner__button--disabled' : ''} inp-spinner__increase`}>+</button>

            <div className={`inp-spinner__quantity-container${isModalOpen ? '--active' : ''}`}>
                <div className="inp-spinner__quantity-backdrop"></div>
                <div className="inp-spinner__quantity-inner-container">
                    <div className="inp-spinner__quantity-header">
                        {__("Please select a multiple of the packaging unit:")}
                    </div>
                    <div className="inp-spinner__quantity-content">
                        {Array.from({length: 10}, (_, i) => {
                            const buttonValue = Math.floor(inputValue / dataVe) + i;
                            if (buttonValue === 0) return null; // Skip rendering when buttonValue is 0
                            return (<button
                                className={"inp-spinner--selector"}
                                key={i}
                                value={buttonValue}
                                onClick={(event) => handleQtyBackdrop(event, buttonValue)}
                            >
                                {dataVe * buttonValue} ({buttonValue} {buttonValue === 1 ? __('packaging unit') : __('packaging units')})
                            </button>);
                        })}
                    </div>
                    <div className="inp-spinner__quantity-footer">
                        <button type="button"
                                className="button-reyher button-reyher--primary inp-spinner__quantity-cancel"
                                onClick={handleCloseClick}
                                title="">
                            <span className="button-reyher__link">{__("Cancel")}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InputSpinnerNew;
