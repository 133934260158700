import {AppContextProvider} from "../../context/AppContextProvider";
import React, {useEffect, useState} from "react";
import LocalStorage from "../../utils/localStorage";
import {__} from "../../i18n";
import Toolbar from "./toolbar/Toolbar";
import MessageBox from "../../components/messages/MessageBox";
import {ianBulkAction} from "../../data/actions/ian-action";
import WishlistRow from "./WishlistRow";
import UrlParsing from "../../utils/urlParsing";

const WishlistList = ({defaultProductList, wishListItemIds}) => {

    const [hits, setHits] = useState([]);
    const [totals, setTotals] = useState(0);

    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [currentLimit, setCurrentLimit] = useState(UrlParsing().getProductListLimit());
    const [currentPage, setCurrentPage] = useState(UrlParsing().getPagination());

    const [getMassActionItems, setMassActionItems] = useState([]);
    const [ianList, setIanList] = useState(null);

    const setData = async (result) => {
        setHits(result.hits?.hits);

        setTotals(result.hits?.total?.value);

        setMassActionItems(result.hits?.hits.map(product => (
            {
                _id: Object.keys(wishListItemIds).find(key => parseInt(wishListItemIds[key]) === parseInt(product._id)),
                sku: product._source.sku,
                qty: product._source.reyher_ve,
                checked: false
            }
        )));
    }

    const fetchIan = async () => {

        if (!LocalStorage.checkIfIsLoggedIn()) {
            return;
        }

        const skuList = getMassActionItems.map((item) => {
            return item.sku
        });

        if (skuList.length) {
            const bodyData = {"skus": skuList}

            const response = await ianBulkAction(bodyData);
            setIanList(response);
        }
    }

    useEffect(async () => {

        await setData(defaultProductList);

        // handling amount and pagination here, reload page
        UrlParsing().urlHandling(currentLimit, currentPage);

        document.addEventListener("legacy-application-ready", () => {
            console.log("legacy-application-ready");

            if (typeof window.TB !== 'undefined') {
                window.TB.findModules(window.jQuery(".js-Modal").parent().get(0));
            }
        })
    }, [currentLimit, currentPage]);

    useEffect(() => {
        fetchIan();
    }, [getMassActionItems]);

    const handleOnChange = (index, qty) => {
        let updatedCheckedState = getMassActionItems;

        updatedCheckedState.forEach((product, idx) => {
            if (idx === parseInt(index)) {
                if (typeof qty === "undefined") {
                    product.checked = !product.checked;
                } else {
                    product.qty = qty;
                    product.checked = true;
                }
            }
        });

        setMassActionItems(updatedCheckedState);
    }

    function getCurrentLimit(limit) {
        setCurrentLimit(limit)
    }

    function getCurrentPage(currentPage) {
        setCurrentPage(currentPage)
    }

    const toggleAllCheckboxes = () => {
        let updatedCheckedState = getMassActionItems;

        updatedCheckedState.forEach((product, idx) => {
            product.checked = true;
        });

        setMassActionItems(updatedCheckedState);
    }

    return (
        <>
            {(totals === 0) && (
                __('No wishlist items')
            )}
            {(totals > 0) &&
            (<AppContextProvider getMassActionItems={getMassActionItems}
                                setMassActionItems={setMassActionItems}
                                ianList={ianList}
                                setIanList={setIanList}>

                <Toolbar totals={totals} currentLimit={getCurrentLimit} currentPage={getCurrentPage} />

                <div className="table-container productlist_table-container">
                    <div className="form-input-set">
                        <table className="table productlist_table">
                            <thead>
                            <tr>
                                <th scope="col">
                                    <div className="form-checkbox-set">
                                        <input id="cbr0" type="checkbox" name="cbr0" value="0"
                                               className="form-checkbox"
                                               onChange={() => toggleAllCheckboxes()}/>
                                        <label form="cbr0"/>
                                    </div>
                                </th>
                                <th scope="col">
                                    {__('articlenumber')}
                                    <br/>
                                    {__('description')}
                                </th>
                                <th scope="col">
                                    {__('qty/ve')}
                                    <br/>

                                    {
                                        (LocalStorage.getArticleCheckPrice() || LocalStorage.getArticleListPrice())
                                        &&
                                        __('catalogprice/100')
                                    }
                                </th>
                                <th scope="col">
                                    {
                                        (LocalStorage.getCartRequestPermission() || LocalStorage.getArticleCheckAvailabilityPermission())
                                        &&
                                        __('qty')
                                    }
                                </th>
                                <th scope="col">
                                    {
                                        LocalStorage.getArticleCustomSkuPermission() &&
                                        __('custom articlenumber')
                                    }
                                </th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {hits?.map((product, index) => (
                                <WishlistRow
                                    index={index}
                                    id={product._id}
                                    product={product._source}
                                    onChange={handleOnChange}
                                    wishlistItemId={Object.keys(wishListItemIds).find(key => parseInt(wishListItemIds[key]) === parseInt(product._id))}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <MessageBox/>
            </AppContextProvider>)}
        </>
    );
}

export default WishlistList;
