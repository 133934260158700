import {__} from "../../i18n";
import {useContext} from "react";
import {MessageContext} from "../../context/MessageContext";
import {deleteItemsFromRfqAction} from "../../data/actions/rfq-action";
import {CartContext} from "../../context/CartContext";

const DeleteFromRfqButton = ({quoteItemId}) => {
    const BASE_URL = window.location.origin || window.BASE_URL;

    const cartId = useContext(CartContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    const handleDelete = async() => {

        const response = await deleteItemsFromRfqAction(cartId, quoteItemId);

        console.log(response);

        response.forEach((el) => {
            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }

            if (el?.success === true && el?.redirect) {
                window.location = BASE_URL + "/" + el.redirect;
            }
        })
    }

    return (

        <button type="button"
                className="jsReactDeleteFromRfqButton button-reyher button-reyher--primary button-reyher--square"
                onClick={handleDelete}
        >
                <span className="button-reyher__link">
                    <span className="reyhericon icon-delete"></span>
                </span>
        </button>
    );
}

export default DeleteFromRfqButton;
