export const header = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
}

const Request = () => {
    return {
        getCustomHeaders() {
            return header;
        }
    }
}
export default Request;
