import _get from 'lodash.get';
import _set from 'lodash.set';

import { config } from '../config';

const LocalStorage = {
    getMagentoLocalStorage() {
        const tokenSource = _get(config, 'storageSource.token', {});

        //if (!LocalStorage.isBrowser()) {
        //    return {};
        //}

        return JSON.parse(
            window.localStorage.getItem(tokenSource.storageKey) || '{}'
        );
    },

    getPermissionResolver() {
        const source = _get(config, 'storageSource.permissionResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getPermissionFromLocalStorage(key) {
        return _get(LocalStorage.getPermissionResolver(), key);
    },

    getCustomer() {
        const source = _get(config, 'storageSource.customer', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getCustomerFromLocalStorage(key) {
        return _get(LocalStorage.getCustomer(), key);
    },

    getCartResolver() {
        const source = _get(config, 'storageSource.cartResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getCartResolverFromLocalStorage(key) {
        return _get(LocalStorage.getCartResolver(), key);
    },

    getCart() {
        const source = _get(config, 'storageSource.cart', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getCartFromLocalStorage(key) {
        return _get(LocalStorage.getCart(), key);
    },

    getRfq() {
        const source = _get(config, 'storageSource.rfqResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getRfqFromLocalStorage(key) {
        return _get(LocalStorage.getRfq(), key);
    },

    setRfqToLocalStorage(qty) {

        const source = _get(config, 'storageSource.rfqResolver', {});

        const rfqResolver = _get(LocalStorage.getMagentoLocalStorage(), source.value);
        rfqResolver.rfqCartCount = qty;

        const storageData = _set(
            LocalStorage.getMagentoLocalStorage(),
            source.value,
            rfqResolver
        );

        window.localStorage.setItem(
            source.storageKey,
            JSON.stringify(storageData)
        );
    },

    setCartQtyToLocalStorage(qty) {

        const source = _get(config, 'storageSource.cart', {});

        const cart = _get(LocalStorage.getMagentoLocalStorage(), source.value);
        cart.summary_count = qty;

        const storageData = _set(
            LocalStorage.getMagentoLocalStorage(),
            source.value,
            cart
        );

        window.localStorage.setItem(
            source.storageKey,
            JSON.stringify(storageData)
        );
    },

    setActiveWishlistByIdToLocalStorage(id) {
        const source = _get(config, 'storageSource.wishlistResolver', {});

        const wishlistResolver = _get(LocalStorage.getMagentoLocalStorage(), source.value);
        wishlistResolver.additional.current_wishlist_id = id;

        const storageData = _set(
            LocalStorage.getMagentoLocalStorage(),
            source.value,
            wishlistResolver
        );

        window.localStorage.setItem(
            source.storageKey,
            JSON.stringify(storageData)
        );
    },

    getWishlist() {
        const source = _get(config, 'storageSource.wishlistResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getWishlistFromLocalStorage(key) {
        return _get(LocalStorage.getWishlist(), key);
    },

    getWishlistResolver() {
        const source = _get(config, 'storageSource.wishlistResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getDebitorResolver() {
        const source = _get(config, 'storageSource.debitorResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getDebitorFromLocalStorage(key) {
        return _get(LocalStorage.getDebitorResolver(), key);
    },

    getWishlistResolverFromLocalStorage(key) {
        return _get(LocalStorage.getWishlistResolver(), key);
    },

    getS4HanaResolver() {
        const source = _get(config, 'storageSource.s4hanaResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    isS4HanaEnabled() {
        return _get(LocalStorage.getS4HanaResolver(), 'enabled');
    },

    getCartApprovalPermission() {
        return this.getPermissionFromLocalStorage('cart_approval');
    },

    getAllowCartPermit() {
        return this.getPermissionFromLocalStorage('allow_cart_permit');
    },

    getCartApprovalRequestPermission() {
        return this.getPermissionFromLocalStorage('cart_approval_request');
    },

    getRioScanPermission() {
        return this.getPermissionFromLocalStorage('RIOSCAN');
    },

    getCartDirectContinuePermission() {
        return this.getPermissionFromLocalStorage('cart_direct_continue');
    },

    getCartOfferPermission() {
        return this.getPermissionFromLocalStorage('cart_offer');
    },

    checkIfIsLoggedIn() {
        return this.getPermissionFromLocalStorage('is_logged_in');
    },

    getFaqPermission() {
        return this.getPermissionFromLocalStorage('help');
    },

    getCartRequestPermission() {
        return this.getPermissionFromLocalStorage('cart_request');
    },

    getCartPermission() {
        return this.getPermissionFromLocalStorage('cart');
    },

    getArticleCheckAvailabilityPermission() {
        return this.getPermissionFromLocalStorage('article_check_availability');
    },

    getNumberOfPiecesPermission() {
        return this.getPermissionFromLocalStorage('STUECKZAHL');
    },

    getAccountPermission() {
        return this.getPermissionFromLocalStorage('account');
    },

    getOrderHistoryPermission() {
        return this.getPermissionFromLocalStorage('order_history');
    },

    getAdminPermission() {
        return this.getPermissionFromLocalStorage('admin');
    },

    getLabelDesignerPermission() {
        return this.getPermissionFromLocalStorage('label_designer');
    },

    checkIfOciUser() {
        return this.getPermissionFromLocalStorage('is_oci_user');
    },

    getArticleCustomSkuPermission() {
        return this.getPermissionFromLocalStorage('article_custom_sku');
    },

    getArticleCheckPrice() {
        return this.getPermissionFromLocalStorage('article_check_price');
    },

    getArticleListPrice() {
        return this.getPermissionFromLocalStorage('article_list_price');
    },

    getArticleCustomSkuEditPermission() {
        return this.getPermissionFromLocalStorage('article_custom_sku_edit');
    },

    getFavoritePermission() {
        return this.getPermissionFromLocalStorage('favorites');
    },

    getCADPermission() {
        return this.getPermissionFromLocalStorage('CAD');
    },

    getUserName() {
        return this.getCustomerFromLocalStorage('username');
    },

    getCartSummaryCount() {
        return this.getCartFromLocalStorage('summary_count');
    },

    getAllCarts() {
        return this.getCartResolverFromLocalStorage('carts');
    },

    getWishlists() {
        return this.getWishlistFromLocalStorage('wishlists');
    },

    getWishlistsAdditionalData() {
        return this.getWishlistFromLocalStorage('additional');
    },

    setActiveWishlistById(id) {
        return this.setActiveWishlistByIdToLocalStorage(id);
    },

    getRfqCartCount() {
        return this.getRfqFromLocalStorage('rfqCartCount');
    },

    setRfqCartCount(count) {
        return this.setRfqToLocalStorage(count);
    },

    setCartCount(count) {
        return this.setCartQtyToLocalStorage(count);
    },


    getDebitorId() {
        return this.getDebitorFromLocalStorage('debitor_id');
    }
};

export default LocalStorage;
