import {
    addProductsToRfqCartService,
    deleteItemsFromRfqService,
    getIdFromCurrentQuoteService,
    getRfqQuoteCountService
} from "../services/rfq-service";

export async function addProductsToRfqCartAction(bodyData) {
    return await addProductsToRfqCartService(bodyData);
}

export async function getRfqQuoteCountAction() {
    return await getRfqQuoteCountService();
}

export async function deleteItemsFromRfqAction(quoteId, quoteItemId) {
    return await deleteItemsFromRfqService(quoteId, quoteItemId);
}

export async function getIdFromCurrentQuoteAction() {
    return await getIdFromCurrentQuoteService();
}

