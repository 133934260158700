import {__} from "../i18n";
import {addProductsToRfqCartAction, getRfqQuoteCountAction} from "../data/actions/rfq-action";
import {useContext} from "react";
import {MassDataContext} from "../context/MassDataContext";
import {MessageContext} from "../context/MessageContext";
import {CartContext} from "../context/CartContext";
import LocalStorage from "../utils/localStorage";

export const useAddToRfqCart = () => {
    const {getMassActionItems, setSuccessfullyAddedProductsToRfqCart,} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    const cartId = useContext(CartContext);

    return async() => {
        const bodyData = {
            cartItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                    qty: parseInt(item.qty, 10), // Ensure qty is a number
                    quote_id: cartId
                }))
        };

        if (!bodyData.cartItems.length) {
            setErrorMessage(__("no products selected"));
            return;
        }

        let response = await addProductsToRfqCartAction(bodyData);

        response.forEach((el) => {
            if (el?.successfullyAdded?.length) {
                setSuccessfullyAddedProductsToRfqCart(el.successfullyAdded);
            }

            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        });

        const rfq = await getRfqQuoteCountAction();
        LocalStorage.setRfqCartCount(JSON.parse(rfq).qty);

        // @todo workaround for knockout stuff
        let dataReload = new CustomEvent("customer-data-reload-rfq_resolver", {});
        document.dispatchEvent(dataReload);
    }
}
