import React, {useContext, useEffect, useState} from 'react';
import {addProductsToRfqCartAction, getRfqQuoteCountAction} from "../../data/actions/rfq-action";
import LocalStorage from "../../utils/localStorage";
import {__} from "../../i18n";
import {MessageContext} from "../../context/MessageContext";
import {MassDataContext} from "../../context/MassDataContext";
function RfqButton({product, qty = 0}) {
    const [isAddToRfqCartSuccessful, setAddToRfqCartSuccessful] = useState(false);

    const {successfullyAddedProductsToRfqCart} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    useEffect(() => {
        if (successfullyAddedProductsToRfqCart?.includes(product.sku)) {
            setAddToRfqCartSuccessful(true);
        }
    }, [successfullyAddedProductsToRfqCart]);

    const handleAddToRfqCart = async() => {
        const bodyData = {
            "cartItems": [{
                "sku": product.sku,
                "qty": qty ? qty : product.reyher_ve,
                "quote_id": "empty" // set required field to some placeholder
            }]
        };

        const response = await addProductsToRfqCartAction(bodyData);

        response.forEach((el) => {
            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
                setAddToRfqCartSuccessful(true);
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        })

        const rfq = await getRfqQuoteCountAction();
        LocalStorage.setRfqCartCount(JSON.parse(rfq).qty);

        // @todo workaround for knockout stuff
        let dataReload = new CustomEvent("customer-data-reload-rfq_resolver", {});
        document.dispatchEvent(dataReload);
    }

    return (
        LocalStorage.checkIfIsLoggedIn() &&
        LocalStorage.getCartRequestPermission() &&
            <button
               onClick={handleAddToRfqCart}
               className={"jsReactRfqButton button-reyher button-reyher--primary button-reyher--square" + (isAddToRfqCartSuccessful ? " ajax-success" : "")}>
                <span className="button-reyher__link">
                    <span className="reyhericon icon-anfragen"></span>
                </span>
            </button>
    );
}
export default RfqButton;
