import Request from "../../utils/request";

const BASE_URL = window.location.origin || window.BASE_URL;

export async function addProductsToRfqCartService(bodyData) {
    try {
        const apiUrl = BASE_URL + "/rest/V1/rfq/inquiry-carts/mine/items";

        return await fetch(apiUrl, {
            method: "POST",
            headers: Request().getCustomHeaders(),
            body: JSON.stringify(bodyData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    } catch (e) {
        console.error("Cart Service Error:", e);
        throw e;
    }
}

export async function getRfqQuoteCountService() {
    try {
        const apiUrl = BASE_URL + "/rest/V1/rfq/mine/quotecount";

        return await fetch(apiUrl, {
            method: "GET",
            headers: Request().getCustomHeaders(),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    } catch (e) {
        console.error("Cart Service Error:", e);
        throw e;
    }
}

export async function deleteItemsFromRfqService(quoteId, quoteItemId) {
    try {
        const apiUrl = BASE_URL + "/rest/V1/rfq/carts/" + quoteId + "/" + quoteItemId;

        return await fetch(apiUrl, {
            method: "DELETE",
            headers: Request().getCustomHeaders(),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    } catch (e) {
        console.error("Cart Service Error:", e);
        throw e;
    }
}

export async function getIdFromCurrentQuoteService() {
    try {
        const apiUrl = BASE_URL + "/rest/V1/rfq/carts/mine";

        return await fetch(apiUrl, {
            method: "GET",
            headers: Request().getCustomHeaders(),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    } catch (e) {
        console.error("Cart Service Error:", e);
        throw e;
    }
}
