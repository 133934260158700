import {__} from "../i18n";
import {addProductsToCartAction, getCustomerCartAction} from "../data/actions/cart-action";
import {useContext} from "react";
import {MassDataContext} from "../context/MassDataContext";
import {MessageContext} from "../context/MessageContext";
import {CartContext} from "../context/CartContext";
import LocalStorage from "../utils/localStorage";

export const useAddToCart = () => {

    const cartId = useContext(CartContext);
    const {getMassActionItems, setSuccessfullyAddedProductsToCart,} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);



    return async() => {
        console.info("click mass action - add to cart");

        const bodyData = {
            cartItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                    qty: parseInt(item.qty, 10), // Ensure qty is a number
                    quote_id: cartId
                }))
        };

        if (!bodyData.cartItems.length) {
            console.error("no products selected");
            setErrorMessage(__("no products selected"));
            return;
        }

        let response = await addProductsToCartAction(cartId, bodyData);

        response.forEach((el) => {
            if (el?.successfullyAdded?.length) {
                setSuccessfullyAddedProductsToCart(el.successfullyAdded);
            }

            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        });

        // todo check if we can extend add to cart api for fetch current items count
        let cart = await getCustomerCartAction();
        LocalStorage.setCartCount(cart.items_count);

        // @todo workaround for knockout stuff
        let dataReload = new CustomEvent("customer-data-reload-cart_resolver", {});
        document.dispatchEvent(dataReload);
    }
}
