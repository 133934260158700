import AddToWishlistButton from "../../components/button/AddToWishlistButton";
import CadButton from "../../components/button/CadButton";
import AddToCartButton from "../../components/button/AddToCartButton";
import RfqButton from "../../components/button/RfqButton";
import {useState} from "react";
import {__} from "../../i18n";
import Ian from "../../components/ian/Ian";
import InputSpinnerNew from "../../components/InputSpinnerNew";
import {openDetailLayerAction} from "../../data/actions/detail-layer-action";

const OrderHistoryRow = ({index, id, orderItem, onChange}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [getQty, setQty] = useState(0);

    const checkHandler = (index, qty) => {
        console.info("click checkbox", index, qty);

        // is qty not changed, then use ve
        if (typeof qty === "undefined") {
            setIsChecked(!isChecked);
        } else {
            setIsChecked(true);
        }

        setQty(qty)
        onChange(index, qty)
    }

    const openDetailLayer = async (event) => {
        await openDetailLayerAction(orderItem, event)
    }

    return (
        <>

        <tr className="order-item-row">
            <td>
                <div className="form-checkbox-set">
                    <input id={'cb' + id}
                           type="checkbox"
                           name={'cb' + orderItem.name}
                           value="0"
                           className="form-checkbox jsReactCheckBox"
                           data-product-sku={orderItem.sku}
                           data-product-id={orderItem.sku}
                           checked={isChecked}
                           onChange={() => checkHandler(index, undefined)}
                    />
                    <label htmlFor="">
                        <span className=""></span>
                    </label>
                </div>
            </td>
            <td>
                <a className="productlist_item_anchor js-Modal d-inline-block"
                   onClick={(ev) => openDetailLayer(ev)}
                   data-title={orderItem.name}>
                    {orderItem.sku}
                </a>
                <div className="d-lg-none">{__('Quantity/VE')}</div>

                <div className="d-block d-lg-none margin-top-20">
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Quantity/VE')}
                        </div>
                        <div className="productlist_table-column-value">
                            {orderItem.ordered_qty}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Weight')}
                        </div>
                        <div className="productlist_table-column-value">
                            {orderItem.row_weight}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">
                            {__('Total')}<br/>
                            {__('Price/100')}
                        </div>
                        <div className="productlist_table-column-value">
                            {orderItem.total_price}
                            <br/>
                            {orderItem.item_price}
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">{__('custom articlenumber')}</div>
                        <div className="productlist_table-column-value">
                            <Ian product={orderItem} useInputField={false}/>
                        </div>
                    </div>
                    <div className="cartlist_table-column">
                        <div className="productlist_table-column-label">{__('custom Orderposition/-text')}</div>
                        <div className="productlist_table-column-value">
                            {orderItem.sap_positionnumber}&nbsp;{orderItem.sap_positiontext}
                        </div>
                    </div>
                </div>

            </td>
            <td>{orderItem.name}</td>
            <td className="text-align-right">{orderItem.ordered_qty}</td>
            <td>
                <div data-id={'sp-' + orderItem.sku}>
                    <InputSpinnerNew
                        index={index}
                        id={'sp-' + orderItem.sku}
                        productId={id}
                        ve={orderItem.reyher_ve}
                        defaultInput={orderItem.ordered_qty}
                        triggerOnChange={(qty) => checkHandler(index, qty)}
                    />
                </div>
            </td>
            <td className="text-align-right">
                {orderItem.row_weight}
            </td>
            <td className="text-align-right">
                {orderItem.total_price}
                <br/>
                {orderItem.item_price}
            </td>
            <td>
                <Ian product={orderItem} useInputField={false}/>
                <br/>
                {orderItem.custom_positionnumber}&nbsp;{orderItem.custom_positiontext}
            </td>
            <td className="text-align-right">
                <AddToCartButton product={orderItem} qty={getQty} />
                <RfqButton product={orderItem} qty={getQty} />
                <CadButton product={orderItem} isEnabled={!!orderItem.reyher_cad_table} />
                <AddToWishlistButton product={orderItem} qty={getQty} />
            </td>
        </tr>
        </>
    );
}

export default OrderHistoryRow;
