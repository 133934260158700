import React from 'react';
import {preOrderCadAction} from "../../data/actions/cad-action";
import {__} from "../../i18n";
import LocalStorage from "../../utils/localStorage";
function CadButton({product, isEnabled = true}) {
    const handleClick = async() => {
        console.info("click cad single button");

        if (isEnabled) {
            const bodyData = {
                productItems: [{
                    sku: product.sku
                }]
            };
            const response = await preOrderCadAction(bodyData);
        }
    };
    return (
        LocalStorage.checkIfIsLoggedIn() &&
        LocalStorage.getCADPermission() &&
            <div className="productlist_list__button--cad">
                <button type="button"
                        className="jsReactCadButton button-reyher button-reyher--primary button-reyher--square"
                        title={__("CAD")}
                        onClick={handleClick}
                        disabled={!isEnabled}
                >
                        <span className="button-reyher__link">{__("CAD")}</span>
                </button>
            </div>
    );
}
export default CadButton;
