import {
    addProductsToWishlistService, moveWishlistService,
    removeFromWishlistService,
    removeItemsFromWishlistService,
    updateQtyInWishlistService
} from "../services/wishlist-service";
export async function addProductsToWishlistAction(bodyData) {
    return await addProductsToWishlistService(bodyData)
}

export async function removeItemsFromWishlistAction(bodyData) {
    return await removeItemsFromWishlistService(bodyData)
}

export async function updateQtyInWishlistAction(bodyData) {
    return await updateQtyInWishlistService(bodyData)
}

export async function moveWishlistAction(bodyData, wishlistId) {
    return await moveWishlistService(bodyData, wishlistId)
}

export async function removeFromWishlistAction(bodyData) {
    return await removeFromWishlistService(bodyData)
}
