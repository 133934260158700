import React from "react";
import ReactDOM from 'react-dom';

import Elements from "./utils/elements";

import NavigationBar from "./components/navigation/NavigationBar";
import MetaNavigation from "./components/navigation/MetaNavigation";
import NavigationMiniCart from "./components/navigation/NavigationMiniCart";
import MenuMobile from "./components/navigation/MenuMobile";
import Dropdown,{createDropdownItem} from "./components/dropdown/Dropdown";
import SelectBox from "./components/dropdown/Selectbox";
import SelectboxNoInput from "./components/dropdown/SelectboxNoInput";
import InputSpinner from "./components/InputSpinner";
import SingleActionButton from "./components/button/SingleActionButton";
import FlyoutWithText from "./components/button/FlyoutWithText";
import PrimaryButton from "./components/button/PrimaryButton";
import SecondaryButton from "./components/button/SecondaryButton";
import InfoBox from "./components/flyout/InfoBox";
import ManufacturerBox from "./components/flyout/ManufacturerBox";

/**
 * LISTS
 */
import WishlistList from "./app/wishlist/WishlistList";
import ProductList from "./app/productlist/ProductList";
import RfqCart from "./app/rfq/quote/RfqCart";
import OrderHistoryList from "./app/orderhistory/OrderHistoryList";

/**
 * BUTTONS
 */
import AddToCartButton from "./components/button/AddToCartButton";
import AddToWishlistButton from "./components/button/AddToWishlistButton";
import AddToRfqButton from "./components/button/RfqButton";
import AddToCadButton from "./components/button/CadButton";
import OfferCart from "./app/rfq/offer/OfferCart";
import RioScanList from "./app/rioscan/RioScanList";
import InputSpinnerNew from "./components/InputSpinnerNew";

window.addEventListener("DOMContentLoaded", (event) => {
    let metaNavigationElement = Elements.getMetaNavigationElement();
    let navigationElement = Elements.getNavigationElement();
    let navigationMiniCartElement = Elements.getMiniCartElement();
    let navigationMenuMobile = Elements.getMenuMobile();
    let dropdownElement = Elements.getDropdownElement();
    let selectboxElement = Elements.getSelectboxElement();
    let selectBoxNoInputElement = Elements.getSelectboxNoInputElement();
    let inputSpinnerElements = Elements.getInputSpinnerElementOnList();
    let singleActionButtonElement = Elements.getSingleActionButtonElement();
    let flyoutWithText = Elements.getFlyoutWithText();
    let primaryButton = Elements.getPrimaryButton();
    let secondaryButton = Elements.getSecondaryButton();
    let dropdownElements = Elements.getDropdownElements();
    let addToCartButtons = Elements.getAddToCartButtons();
    let addToRfqButtons = Elements.getAddToRfqButtons();
    let addToCadButtons = Elements.getAddToCadButtons();
    let addToWishlistButtons = Elements.getAddToWishlistButtons();

    /**
     * LISTS
     */
    const productList = Elements.getProductList();
    const wishList = Elements.getWishList();
    const orderHistoryList = Elements.getOrderHistory();
    const offerList = Elements.getOfferList();

    const rfqCartSelectorList = Elements.getRfqCartSelector();
    const offerSelector = Elements.getOfferSelector();
    const rioScanSelector = Elements.getRioScanSelector();

    /**
     * BUTTONS
     */
    const addToCartButtonSelector =  Elements.getAddToCartButtonSelector();
    const cadButtonSelector =  Elements.getCadButtonSelector();
    const rfqButtonSelector =  Elements.getRfqButtonSelector();
    const deleteFromWishlistButtonSelector =  Elements.getDeleteFromWishlistButtonSelector();


    if (metaNavigationElement) {
        ReactDOM.render(<MetaNavigation />, metaNavigationElement);
    }

    if (navigationElement) {
        ReactDOM.render(<NavigationBar />, navigationElement);
    }

    if (navigationMiniCartElement) {
        ReactDOM.render(<NavigationMiniCart />, navigationMiniCartElement);
    }

    if (navigationMenuMobile) {
        ReactDOM.render(<MenuMobile />, navigationMenuMobile);
    }

    //@todo translation
    if (dropdownElement) {
        const initialState = "Aktion Auswählen";
        const dropdownItems = [
            createDropdownItem("Warenkorb leeren", "#", "request", true),
            createDropdownItem("Aus Warenkorb entfernen", "#", "test", true),
            createDropdownItem("Artikel verschieben", "#", "test", true),
        ];
        ReactDOM.render(
            <Dropdown initialState={initialState} dropdownItems={dropdownItems} />,
            dropdownElement
        );
    }

    if (dropdownElements) {
        dropdownElements.forEach((element) => {
            const initialState = element.getAttribute('data-initial');
            const spanElements = element.querySelectorAll('span');
            const dropdownItems = Array.from(spanElements).map(span => {
                const name = span.getAttribute('data-name');
                const href = span.getAttribute('data-href');
                const action = span.getAttribute('data-action');
                const permissions = span.getAttribute('data-permissions') === "true";
                return createDropdownItem(name, href, action, permissions);
            });

            ReactDOM.render(
                <Dropdown initialState={initialState} dropdownItems={dropdownItems} />,
                element
            );
        })
    }

    if (addToCartButtons) {
        addToCartButtons.forEach((element) => {
            const sku = element.getAttribute('data-sku');
            const qty = element.getAttribute('data-qty');
            ReactDOM.render(<AddToCartButton sku={sku} qty={qty}/>, element);
        });
    }

    if (addToRfqButtons) {
        addToRfqButtons.forEach((element) => {
            const productId = element.getAttribute('data-product');
            const qty = element.getAttribute('data-qty');
            ReactDOM.render(<AddToRfqButton productId={productId} qty={qty}/>, element);
        });
    }

    if (addToCadButtons) {
        addToCadButtons.forEach((element) => {
            const post = element.getAttribute('data-post');
            const disabled = element.getAttribute('data-disabled');
            ReactDOM.render(<AddToCadButton postData={post} disabled={disabled}/>, element);
        });
    }

    if (addToWishlistButtons) {
        addToWishlistButtons.forEach((element) => {
            const post = element.getAttribute('data-post');
            const productId = element.getAttribute('data-productId');
            ReactDOM.render(<AddToWishlistButton postData={post} productId={productId} />, element);
        });
    }

    if (selectboxElement) {
        ReactDOM.render(<SelectBox />, selectboxElement);
    }

    if (selectBoxNoInputElement) {
        ReactDOM.render(<SelectboxNoInput />, selectBoxNoInputElement);
    }

    if (inputSpinnerElements) {
        // Loop through each element and render the React component
        inputSpinnerElements.forEach((element) => {
            const dataId = element.getAttribute('data-id');
            const productId = element.getAttribute('data-product-id');
            const ve = element.getAttribute('data-ve');
            const productAttributeDawn = element.getAttribute('data-product-attribute-dawn');
            const qty = element.getAttribute('data-qty');
            const itemId = element.getAttribute('data-item-id');
            const forceLoad =  !! element.getAttribute('data-force-load');
            const name = element.getAttribute('data-name');

            ReactDOM.render(
                <InputSpinner
                    id={dataId}
                    productId={productId}
                    ve={ve}
                    productAttributeDawn={productAttributeDawn}
                    itemId={itemId}
                    input={qty}
                    forceLoadingComponent={forceLoad}
                    name={name}
                />,
                element
            );
        });
    }

    if (singleActionButtonElement) {
        ReactDOM.render(<SingleActionButton />, singleActionButtonElement);
    }

    if (flyoutWithText) {
        ReactDOM.render(<FlyoutWithText />, flyoutWithText);
    }

    if (primaryButton) {
        ReactDOM.render(<PrimaryButton />, primaryButton);
    }

    if (secondaryButton) {
        ReactDOM.render(<SecondaryButton />, secondaryButton);
    }

    /**
     * LISTS
     */
    if (productList) {
        const list = productList.getAttribute('data-collection');

        //@todo fallback
        ReactDOM.render(<ProductList defaultProductList={JSON.parse(list)}/>, productList);
    }

    if (wishList) {
        const list = wishList.getAttribute('data-collection');
        const wishListItemIds = wishList.getAttribute('data-wishListItemIds');

        //@todo fallback
        ReactDOM.render(
            <WishlistList defaultProductList={JSON.parse(list)}
                          wishListItemIds={JSON.parse(wishListItemIds)}
            />, wishList);
    }

    if (orderHistoryList) {
        const orderItems = orderHistoryList.getAttribute('data-orderItems');
        const order = orderHistoryList.getAttribute('data-order');
        const quote = orderHistoryList.getAttribute('data-quote');
        const shippingAddress = orderHistoryList.getAttribute('data-shippingAddress');

        //@todo fallback
        ReactDOM.render(
            <OrderHistoryList order={JSON.parse(order)}
                              orderItems={JSON.parse(orderItems)}
                              quote={JSON.parse(quote)}
                              shippingAddress={JSON.parse(shippingAddress)}
            />, orderHistoryList);
    }

    if (rfqCartSelectorList) {
        rfqCartSelectorList.forEach((rfqElement) => {
            const product = rfqElement.getAttribute('data-product');
            const quoteItemId = rfqElement.getAttribute('data-quoteItemId');

            ReactDOM.render(
                <RfqCart
                    product={JSON.parse(product)}
                    quoteItemId={quoteItemId}
                />,
                rfqElement
            );
        });
    }

    if (offerList) {
        const offer = offerList.getAttribute('data-offer');
        const offerItems = offerList.getAttribute('data-offerItems');
        const hasItemsOfExpiredOffers = !!offerList.getAttribute('data-hasItemsOfExpiredOffers');

        ReactDOM.render(
            <OfferCart
                offer={JSON.parse(offer)}
                offerItems={JSON.parse(offerItems)}
                hasItemsOfExpiredOffers={hasItemsOfExpiredOffers}
            />,
            offerList
        );
    }

    if (rioScanSelector) {
        rioScanSelector.forEach((rioScanElement) => {
            const product = rioScanElement.getAttribute('data-product');

            ReactDOM.render(
                <RioScanList
                    product={JSON.parse(product)}

                />,
                rioScanElement
            );
        });
    }


    /**
     * BUTTONS
     */
    if (addToCartButtonSelector) {
        ReactDOM.render(<AddToCartButton />, addToCartButtonSelector);
    }

    if (cadButtonSelector) {
        ReactDOM.render(<AddToCadButton />, cadButtonSelector);
    }

    if (rfqButtonSelector) {
        ReactDOM.render(<AddToRfqButton />, rfqButtonSelector);
    }

    if (deleteFromWishlistButtonSelector) {
        //ReactDOM.render(<SecondaryButton />, deleteFromWishlistButtonSelector);
    }

});

document.addEventListener('open-modal-finish', function (event) {
    let infoBox = Elements.getFlyoutInfoBox();
    let manufacturerBox = Elements.getFlyoutManufacturerBox();

    let inputSpinnerElement = Elements.getInputSpinnerElementInModal();
    if (inputSpinnerElement) {
        const dataId = inputSpinnerElement.getAttribute('data-id');
        const productId = inputSpinnerElement.getAttribute('data-product-id');
        const ve = inputSpinnerElement.getAttribute('data-ve');
        const productAttributeDawn = inputSpinnerElement.getAttribute('data-product-attribute-dawn');

        let initialQtyFromList = event.detail.qty;
        let defaultInput = initialQtyFromList !== null && initialQtyFromList !== undefined ? Number(initialQtyFromList) : ve;

        ReactDOM.render(
            <InputSpinnerNew
                index={0}
                id={dataId}
                productId={productId}
                triggerOnChange={(qty) => console.log(qty)}
                dawnAttribute={productAttributeDawn}
                ve={ve}
                defaultInput={defaultInput}
            />,
            inputSpinnerElement
        );
    }

    if (infoBox) {
        ReactDOM.render(<InfoBox />, infoBox);
    }

    if (manufacturerBox) {
        ReactDOM.render(<ManufacturerBox manufacturer={manufacturerBox.getAttribute('data-name')} />, manufacturerBox);
    }
});
