import {__} from "../../i18n";
import {removeItemsFromWishlistAction} from "../../data/actions/wishlist-action";
import {useContext} from "react";
import {MessageContext} from "../../context/MessageContext";

const DeleteFromWishlistButton = ({wishlistItemId}) => {
    const BASE_URL = window.location.origin || window.BASE_URL;

    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    const handleDeleteFromWishlist = async() => {
        const bodyData = {
            wishlistItems: [{
                item_id: wishlistItemId
            }]
        };

        const response = await removeItemsFromWishlistAction(bodyData);

        response.forEach((el) => {
            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }

            if (el?.success === true && el?.redirect) {
                window.location = BASE_URL + "/" + el.redirect;
            }
        })
    }

    return (
        <button type="button"
                className="jsReactDeleteFromWishlistButton button-reyher button-reyher--primary button-reyher--square"
                onClick={handleDeleteFromWishlist}
        >
            <span className="button-reyher__link">
                <span className="reyhericon icon-delete"></span>
            </span>
        </button>
    );
}

export default DeleteFromWishlistButton;
