import {preOrderCadAction} from "../data/actions/cad-action";
import {useContext} from "react";
import {MassDataContext} from "../context/MassDataContext";

export const useRequestCad = () => {

    const {getMassActionItems,} = useContext(MassDataContext);

    return async() => {
        const bodyData = {
            productItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                }))
        };

        const response = await preOrderCadAction(bodyData);
    }
}
