import React, { useEffect, useState } from 'react';
import DropdownItem from "../dropdown/DropdownItem";
import LocalStorage from "../../utils/localStorage";
import useOutsideClick from "../../hooks/useOutsideClick";

function FlyoutComponent({ position, dropdownItems, header, activeProp, buttonRef}) {
    const initialState = () => {
        const activeElement = dropdownItems?.find(item => item[activeProp] === '1');

        return activeElement ? activeElement.name : dropdownItems[0]?.name;
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState(initialState);
    const [flyoutPosition, setFlyoutPosition] = useState(position);


    // @todo use custom hook
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleScroll() {
        setIsFlyoutOpen(false);
    }

    const handleClickOutside = () => {
        setIsFlyoutOpen(false);
    }

    const flyoutRef = useOutsideClick(handleClickOutside);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (itemName, id) => {
        LocalStorage.setActiveWishlistById(id);

        setSelectedItem(itemName);
        setIsOpen(false);
    };

    const onCloseHandler = () => {
        setIsFlyoutOpen(false);
    }

    const onSubmitHandler = () => {
        buttonRef.current.click();
    }

    useEffect(() => {
        setIsFlyoutOpen(true);
        setFlyoutPosition(position);

    }, [position]);

    return (
        <div className={`flyout-reyher flyout-reyher--right ${isFlyoutOpen ? 'flyout-reyher--open' : ''}`}
             style={{ top: flyoutPosition.top, left: flyoutPosition.left, position: "fixed", width: "329px"}}
             ref={flyoutRef}
        >
            <div style={{display: "block"}}>
                <div className="flyout-reyher__headline">
                    <h6 style={{textAlign: "left"}}>{header}</h6>
                    <a className="flyout-reyher__close"
                       aria-label="Close"
                       onClick={() => onCloseHandler()}
                    >
                        <span className="reyhericon icon-close"></span>
                    </a>
                </div>
                <div className={"flyout-reyher__content"}>
                    <div className="d-flex justify-content-space-between">
                        {/*TODO dynamically set dropdown-reyher--link-button on lower adjacent element */}
                        <div className={`dropdown-reyher ${isOpen ? 'dropdown-reyher--open' : ''}`}>
                            <a style={{width: "100%"}} className={`dropdown-reyher__toggle ${selectedItem !== initialState ? 'dropdown-reyher__toggle--active' : ''}`}
                               onClick={toggleDropdown} data-value="default">
                                {selectedItem}
                            </a>
                            <ul className="dropdown-reyher__list dropdown-reyher__content" style={{width: "100%"}}>
                                {dropdownItems?.map((item, index) => (
                                    <DropdownItem
                                        key={index}
                                        id={item.id}
                                        name={item.name}
                                        href="#"
                                        action=""
                                        permission={true}
                                        onItemClick={handleItemClick}
                                    />
                                ))}
                            </ul>
                        </div>
                        <div className="margin-left-half">
                            <button type="button"
                                    style={{width: "40px", height: "40px"}}
                                    className="button-reyher button-reyher--primary button-reyher--square"
                                    onClick={() => onSubmitHandler()}
                            >
                                <span className="button-reyher__link">
                                    <span className="reyhericon icon-arrow"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlyoutComponent;
