const Elements = {

    getSkuSearchElement() {
        return document.getElementById('flyout-freetext');
    },

    getMetaNavigationElement() {
        return document.getElementById('jsReactMetaNav');
    },

    getNavigationElement() {
        return document.getElementById('jsReactNav');
    },

    getMenuMobile() {
        return document.getElementById('jsReactMenuMobile');
    },

    getMiniCartElement() {
        return document.getElementById('jsReactNavMiniCart');
    },

    getInputSpinnerElementOnList() {
        return document.querySelectorAll('.jsReactInputSpinner');
    },

    //getInputSpinnerElement() {
    //    return document.querySelector('.modal #jsReactInputSpinner');
    //},

    getInputSpinnerElementInModal() {
        return document.getElementById('jsReactInputSpinnerInModal');
    },

    getModalElement() {
        return document.getElementById('jsReactModal');
    },

    getDropdownElement() {
        return document.getElementById('jsDropdown');
    },

    getDropdownElements() {
        return document.querySelectorAll('.jsDropdownReact');
    },

    getSelectboxElement() {
        return document.getElementById('jsSelectbox');
    },

    getSelectboxNoInputElement() {
        return document.getElementById('jsSelectboxNoInput');
    },

    getFlyoutWithText() {
        return document.getElementById('jsFlyoutWithText');
    },



    /**
     * LISTS
     */
    getProductList() {
        return document.getElementById('jsReactProductList');
    },

    getWishList() {
        return document.getElementById('jsReactWishList');
    },

    getOrderHistory() {
        return document.getElementById('jsReactOrderHistoryList');
    },

    getOfferList() {
        return document.getElementById('jsReactOfferList');
    },

    getWishListSelector() {
        return document.querySelectorAll('.jsReactWishListSelector');
    },

    getRfqCartSelector() {
        return document.querySelectorAll('.jsReactRfqCartSelector');
    },

    getOrderHistorySelector() {
        return document.querySelectorAll('.jsReactOrderHistorySelector');
    },

    getOfferSelector() {
        return document.querySelectorAll('.jsReactOfferSelector');
    },

    getRioScanSelector() {
        return document.querySelectorAll('.jsReactRioScanSelector');
    },

    getWishListToolbarSelector() {
        return document.querySelectorAll('.jsReactWishListToolbarSelector');
    },







    /**
     * BUTTONS
     */
    //@todo what is this for a button?
    getSingleActionButtonElement() {
        return document.getElementById('jsSingleActionButton');
    },

    getPrimaryButton() {
        return document.getElementById('jsPrimaryButton');
    },

    getSecondaryButton() {
        return document.getElementById('jsSecondaryButton');
    },

    getFlyoutInfoBox() {
        return document.getElementById('jsRecentOrdersFlyout');
    },

    getFlyoutManufacturerBox() {
        return document.getElementById('jsManufacturerBox');
    },

    getAddToCartButtons() {
        return document.querySelectorAll('.jsAddToCartButtonsReact');
    },

    getAddToRfqButtons() {
        return document.querySelectorAll('.jsAddToRfqButtonsReact');
    },

    getAddToCadButtons() {
        return document.querySelectorAll('.jsAddToCadButtonsReact');
    },

    getAddToWishlistButtons() {
        return document.querySelectorAll('.jsAddToWishlistButtonsReact');
    },

    getAddToCartButtonSelector() {
        return document.getElementById('jsReactAddToCartButtonSelector');
    },

    getCadButtonSelector() {
        return document.getElementById('jsReactCadButtonSelector');
    },

    getRfqButtonSelector() {
        return document.getElementById('jsReactRfqButtonSelector');
    },

    getDeleteFromWishlistButtonSelector() {
        return document.getElementById('jsReactDeleteFromWishlistButtonSelector');
    },
};

export default Elements;
