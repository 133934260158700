import RioScanRow from "./RioScanRow";
import {AppContextProvider} from "../../context/AppContextProvider";
import {useEffect, useState} from "react";
import LocalStorage from "../../utils/localStorage";
import {getCustomerCartAction} from "../../data/actions/cart-action";

const RioScanList = ({product}) => {

    const [customerCartId, setCustomerCartId] = useState(null);
    const [getMassActionItems, setMassActionItems] = useState([]);
    const [ianList, setIanList] = useState(null);

    const fetchCustomerCart = async () => {

        if (!LocalStorage.checkIfIsLoggedIn()) {
            return;
        }

        let cart = await getCustomerCartAction();
        setCustomerCartId(cart.id);
    }

    // load customer card
    useEffect(() => {
        fetchCustomerCart();
    }, []);

    return (

        <AppContextProvider customerCartId={customerCartId}
                            setCustomerCartId={setCustomerCartId}
                            getMassActionItems={getMassActionItems}
                            setMassActionItems={setMassActionItems}
                            ianList={ianList}
                            setIanList={setIanList}>
            <RioScanRow product={product} />
        </AppContextProvider>
    );
}

export default RioScanList
