import AddToCartButton from "../../components/button/AddToCartButton";
import AddToWishlistButton from "../../components/button/AddToWishlistButton";
import CadButton from "../../components/button/CadButton";
import RfqButton from "../../components/button/RfqButton";
import {useState} from "react";
import Ian from "../../components/ian/Ian";
import InputSpinnerNew from "../../components/InputSpinnerNew";
import {__} from "../../i18n";
import {openDetailLayerAction} from "../../data/actions/detail-layer-action";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ProductRow = ({index, id, product, onChange}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [getQty, setQty] = useState(0);

    const checkHandler = (index, qty) => {
        console.info("click checkbox", index, qty);

        // is qty not changed, then use ve
        if (typeof qty === "undefined") {
            setIsChecked(!isChecked);
        } else {
            setIsChecked(true);
        }

        setQty(qty)
        onChange(index, qty)
    }

    const openDetailLayer = async (event) => {
        await openDetailLayerAction(product, event)
    }

    return (
        <>
            <tr>
                <td>
                    <div className="form-checkbox-set">
                        <input id={'cb' + id}
                               type="checkbox"
                               name={'cb' + product.name}
                               value="0"
                               className="form-checkbox jsReactCheckBox"
                               data-product-sku={product.sku}
                               data-product-id={product.sku}
                               checked={isChecked}
                               onChange={() => checkHandler(index, undefined)}
                        />
                        <label htmlFor="">
                            <span className=""></span>
                        </label>
                    </div>
                </td>
                <td>
                    <a className="productlist_item_anchor js-Modal d-inline-block"
                       onClick={(ev) => openDetailLayer(ev)}
                       data-title={product.name}>
                        {product.sku}
                    </a>
                    <br/>
                    {product.name}
                    <br/>
                </td>
                <td>
                    <div className="productlist_table-column">
                        <div className="productlist_table-column-label">{__('Quantity/VE')}</div>
                        <div className="productlist_table-column-value">
                            {product.reyher_ve}
                            <br />
                            {product.price}
                        </div>
                    </div>
                </td>
                <td>
                    <div data-id={'sp-' + product.sku}>
                        <InputSpinnerNew
                            index={index}
                            id={'sp-' + product.sku}
                            productId={id}
                            triggerOnChange={(qty) => checkHandler(index, qty)}
                            dawnAttribute={product.reyher_kein_anbruch}
                            ve={product.reyher_ve}
                            defaultInput={product.reyher_ve}
                        />
                    </div>
                </td>
                <td>
                    <Ian product={product} />
                </td>
                <td>
                    <AddToCartButton product={product} qty={getQty} />
                    <RfqButton product={product} qty={getQty} />
                    <CadButton product={product} isEnabled={!!product.reyher_cad_table} />
                    <AddToWishlistButton product={product} qty={getQty} />
                </td>
            </tr>
        </>
    );
}

export default ProductRow;
