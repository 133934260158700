import DropdownItem from "../dropdown/DropdownItem";
import React, {useContext, useState} from "react";
import FlyoutComponent from "../button/FlyoutComponent";
import {useFlyout} from "../button/useFlyout";
import LocalStorage from "../../utils/localStorage";
import {CartContext} from "../../context/CartContext";
import {MassDataContext} from "../../context/MassDataContext";
import {addProductsToCartAction} from "../../data/actions/cart-action";
import {addProductsToRfqCartAction} from "../../data/actions/rfq-action";
import {addProductsToWishlistAction} from "../../data/actions/wishlist-action";
import {preOrderCadAction} from "../../data/actions/cad-action";
import {__} from "../../i18n";
import useOutsideClick from "../../hooks/useOutsideClick"
import {MessageContext} from "../../context/MessageContext";

const MassActions = () => {
    //@todo check permissions
    const availableActions = [
        {id:0, title: __("add to cart")},
        {id:1, title: __("add to request cart")},
        {id:2, title: __("add to wishlist")},
        {id:3, title: __("request cad")},
        {id:4, title: __("create label")},
    ];
    const [isOpen, setIsOpen] = useState(false);
    const [massAction, setMassAction] = useState(null);
    const { isHovered, position, buttonRef, flyoutRef, handleMouseEnter } = useFlyout();

    const [isCartButtonActive, setCartButtonActive] = useState(false);
    const [isRfqButtonActive, setRfqButtonActive] = useState(false);
    const [isWishlistButtonActive, setWishlistButtonActive] = useState(false);
    const [isCadButtonActive, setCadButtonActive] = useState(false);

    const cartId = useContext(CartContext);

    const {
        getMassActionItems,
        setSuccessfullyAddedProductsToCart,
        setSuccessfullyAddedProductsToRfqCart,
        setSuccessfullyAddedProductsToWishlist
    } = useContext(MassDataContext);

    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    const carts = LocalStorage.getAllCarts();
    const wishlists = LocalStorage.getWishlists();

    const handleClickOutside = () => {
        setIsOpen(false);
    }

    const dropDownRef = useOutsideClick(handleClickOutside);

    const handleActionClick = (action, id) => {
        setMassAction(action)
        setIsOpen(false);

        switch (id) {
            case 0:
                setCartButtonActive(true);
                setRfqButtonActive(false);
                setWishlistButtonActive(false);
                setCadButtonActive(false);
                break;
            case 1:
                setRfqButtonActive(true);
                setCartButtonActive(false);
                setWishlistButtonActive(false);
                setCadButtonActive(false);
                break;
            case 2:
                setWishlistButtonActive(true);
                setCartButtonActive(false);
                setRfqButtonActive(false);
                setCadButtonActive(false);
                break;
            case 3:
                setCadButtonActive(true);
                setCartButtonActive(false);
                setRfqButtonActive(false);
                setWishlistButtonActive(false);

                break;
            default:
        }

    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleAddToCart = async() => {
        console.info("click mass action - add to cart");

        const bodyData = {
            cartItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                    qty: parseInt(item.qty, 10), // Ensure qty is a number
                    quote_id: cartId
                }))
        };


        if (!bodyData.cartItems.length) {
            console.error("no products selected");
            setErrorMessage(__("no products selected"));
            return;
        }

        let response = await addProductsToCartAction(cartId, bodyData);

        response.forEach((el) => {
            if (el?.successfullyAdded?.length) {
                setSuccessfullyAddedProductsToCart(el.successfullyAdded);
            }

            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        });
    }

    const handleAddToRfqCart = async() => {
        const bodyData = {
            cartItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                    qty: parseInt(item.qty, 10), // Ensure qty is a number
                    quote_id: cartId
                }))
        };

        if (!bodyData.cartItems.length) {
            setErrorMessage(__("no products selected"));
            return;
        }

        let response = await addProductsToRfqCartAction(bodyData);

        response.forEach((el) => {
            if (el?.successfullyAdded?.length) {
                setSuccessfullyAddedProductsToRfqCart(el.successfullyAdded);
            }

            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        });
    }

    const handleCad = async() => {
        const bodyData = {
            productItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                }))
        };

        const response = await preOrderCadAction(bodyData);
    }

    const handleAddToWishlist = async() => {
        const bodyData = {
            products: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                    qty: parseInt(item.qty, 10), // Ensure qty is a number
                }))
        };

        if (!bodyData.products.length) {
            setErrorMessage(__("no products selected"));
            return;
        }

        const response = await addProductsToWishlistAction(bodyData);

        response.forEach((el) => {
            if (el?.successfullyAdded?.length) {
                setSuccessfullyAddedProductsToWishlist(el.successfullyAdded);
            }

            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        });
    }

    return (
        <div className="productlist_toolbar_actions">
            <div className={`dropdown-reyher dropdown-reyher--link-button ${isOpen ? 'dropdown-reyher--open' : ''}`}
                 ref={dropDownRef}
            >

                <a className={`dropdown-reyher__toggle ${massAction ? 'dropdown-reyher__toggle--active' : ''}`}
                   onClick={toggleDropdown}
                   data-value="default">
                    {massAction ? massAction : __("Choose action")}
                </a>

                <div className="dropdown-reyher__button-container">

                    <button type="button"
                            className={`button-reyher button-reyher--primary button-reyher--square ${(isCartButtonActive || isRfqButtonActive || isCadButtonActive || isWishlistButtonActive) ? 'hidden' : ''}`}
                    >
                        <span className="button-reyher__link">
                            <span className="reyhericon icon-arrow"></span>
                        </span>
                    </button>

                    {
                        isCartButtonActive &&
                        <button type="button"
                                className={`button-reyher button-reyher--primary button-reyher--square`}
                                onClick={handleAddToCart}
                                onMouseEnter={handleMouseEnter}
                                ref={buttonRef}>
                            <span className="button-reyher__link">
                                <span className="reyhericon icon-arrow"></span>
                            </span>
                        </button>
                    }
                    {isCartButtonActive && isHovered && carts?.length > 1 && (
                        <FlyoutComponent
                            position={position}
                            dropdownItems={carts}
                            header={__("Favoritenliste")}
                            activeProp={"is_active"}
                            buttonRef={buttonRef}
                        />
                    )}

                    <button type="button"
                            className={`button-reyher button-reyher--primary button-reyher--square ${isRfqButtonActive ? '' : 'hidden'}`}
                            onClick={handleAddToRfqCart}>
                        <span className="button-reyher__link">
                            <span className="reyhericon icon-arrow"></span>
                        </span>
                    </button>

                    <button type="button"
                            className={`button-reyher button-reyher--primary button-reyher--square ${isCadButtonActive ? '' : 'hidden'}`}
                            onClick={handleCad}>
                        <span className="button-reyher__link">
                            <span className="reyhericon icon-arrow"></span>
                        </span>
                    </button>

                    {
                        isWishlistButtonActive &&
                        <button type="button"
                                className={`button-reyher button-reyher--primary button-reyher--square`}
                                onClick={handleAddToWishlist}
                                onMouseEnter={handleMouseEnter}
                                ref={buttonRef}>
                            <span className="button-reyher__link">
                                <span className="reyhericon icon-arrow"></span>
                            </span>
                        </button>
                    }

                    {isWishlistButtonActive && isHovered && wishlists?.length > 1 && (
                        <FlyoutComponent
                            position={position}
                            dropdownItems={wishlists}
                            header={__("choose cart")}
                            activeProp={"is_active"}
                            buttonRef={buttonRef}
                        />
                    )}

                </div>

                <ul className="dropdown-reyher__list dropdown-reyher__content">
                    {availableActions.map((action) => (
                        <DropdownItem
                            id={action.id}
                            name={action.title}
                            href="#"
                            action={action.title}
                            permission={true}
                            onItemClick={handleActionClick}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
}
export default MassActions;
