import React, {useState} from 'react';
import Amount from "./Amount";
import MassActionsNew from "./MassActionsNew";
import Pagination from "./Pagination";
import {ToolbarContext} from "../../context/ToolbarContext";
import UrlParsing from "../../utils/urlParsing";
import {__} from "../../i18n";

import {useAddToCart} from "../../hooks/useAddToCart";
import {useAddToRfqCart} from "../../hooks/useAddToRfqCart";
import {useAddToWishlist} from "../../hooks/useAddToWishlist";
import {useRequestCad} from "../../hooks/useRequestCad";

import LocalStorage from "../../utils/localStorage";
import {createLabelAction} from "../../data/actions/create-label-action";

const Toolbar = ({totals = null, currentLimit= null, currentPage= null}) => {
    const limit = UrlParsing().getProductListLimit();
    const [pageSize, setPageSize] = useState(limit);

    const addToCart = useAddToCart();
    const addToRfqCart = useAddToRfqCart();
    const addToWishlist = useAddToWishlist();
    const requestCad = useRequestCad();

    const wishlists = LocalStorage.getWishlists();
    const carts = LocalStorage.getAllCarts();

    const hasMultipleWishlists = wishlists?.length > 1
    const hasMultipleCarts = carts?.length > 1;


    const availableActions = [
        {id:0, value: "addToCart", label: __("add to cart"), onClick: () => addToCart(), hasHover: hasMultipleCarts, hoverOptions: carts, flyoutHeader: __("choose cart")},
        {id:1, value: "addToRfqCart", label: __("add to request cart"), onClick: () => addToRfqCart()},
        {id:2, value: "addToWishlist", label: __("add to wishlist"), onClick: () => addToWishlist(), hasHover: hasMultipleWishlists, hoverOptions: wishlists, flyoutHeader: __("Favourite list")},
        {id:3, value: "requestCad", label: __("request cad"), onClick: () => requestCad()},
        {id:4, value: "createLabel", label: __("create label"), onClick: () => createLabelAction()},
    ];

    return (
        <div className="productlist_toolbar productlist_toolbar--top">
            <ToolbarContext.Provider value={{limit, pageSize, setPageSize}} >
                <Amount currentLimit={currentLimit}/>
                <Pagination totals={totals} currentPage={currentPage}/>
                <MassActionsNew options={availableActions}/>
            </ToolbarContext.Provider>
        </div>
    );
}

export default Toolbar;
