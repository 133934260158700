import LocalStorage from "../../../utils/localStorage";
import CadButton from "../../../components/button/CadButton";
import AddToWishlistButton from "../../../components/button/AddToWishlistButton";
import DeleteFromRfqButton from "../../../components/button/DeleteFromRfqButton";

const RfqCartRow = ({product, quoteItemId}) => {
    return (
        <>
            <DeleteFromRfqButton quoteItemId={quoteItemId} />

            {
                LocalStorage.checkIfIsLoggedIn() &&
                LocalStorage.getCADPermission() &&
                <CadButton product={product} isEnabled={!!product.reyher_cad_table} />
            }

            {
                LocalStorage.checkIfIsLoggedIn() &&
                <AddToWishlistButton productId={product.entity_id} product={product} />
            }

        </>
    );
}

export default RfqCartRow;
