import React, {useContext, useEffect, useState} from 'react';
import FlyoutComponent from "./FlyoutComponent";
import {useFlyout} from "./useFlyout";
import LocalStorage from "../../utils/localStorage";
import {addProductsToWishlistAction} from "../../data/actions/wishlist-action";
import {__} from "../../i18n";
import {MassDataContext} from "../../context/MassDataContext";
import {MessageContext} from "../../context/MessageContext";

function AddToWishlistButton({product, qty = 0}) {
    const { isHovered, position, buttonRef, handleMouseEnter } = useFlyout();
    const [isAddToWishlistSuccessful, setAddToWishlistSuccessful] = useState(false);

    const wishlists = LocalStorage.getWishlists();
    const additionalData = LocalStorage.getWishlistsAdditionalData();

    const {successfullyAddedProductsToWishlist} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    useEffect(() => {
        if (successfullyAddedProductsToWishlist?.includes(product.sku)) {
            setAddToWishlistSuccessful(true);
        }
    }, [successfullyAddedProductsToWishlist]);

    const handleAddToWishlist = async() => {

        const bodyData = {
            "products": [{
                "sku": product.sku,
                "qty": qty ? qty : product.reyher_ve,
                "wishlist_id": additionalData.current_wishlist_id
            }]
        };

        const response = await addProductsToWishlistAction(bodyData);

        response.forEach((el) => {
            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
                setAddToWishlistSuccessful(true);
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        })
    }

    return (
        LocalStorage.checkIfIsLoggedIn() &&
            <>
                <button
                    type="button"
                    className={"jsReactWishlistButton button-reyher button-reyher--primary button-reyher--square" + (isAddToWishlistSuccessful ? " ajax-success" : "")}
                    title="Zu Favoriten hinzufügen"
                    aria-label="Zu Favoriten hinzufügen"
                    onClick={handleAddToWishlist}
                    onMouseEnter={handleMouseEnter}
                    ref={buttonRef}
                >
                    <span className="button-reyher__link">
                        <span className="reyhericon icon-favorite"></span>
                    </span>
                </button>
                {isHovered && wishlists?.length > 1 && (
                    <FlyoutComponent
                        position={position}
                        dropdownItems={wishlists}
                        header={"Favoritenliste"}
                        activeProp={"is_favorite"}
                        buttonRef={buttonRef}
                    />
                )}
            </>
    );
}
export default AddToWishlistButton;
