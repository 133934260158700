import React, { useEffect, useState, useRef } from "react";
import LocalStorage from "../../utils/localStorage";
import $ from 'jquery';
import {__} from "../../i18n";

function ManufacturerBox(manufacturer) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formattedDate, setFormattedDate] = useState('');
    const [showModal, setShowModal] = useState(false);

    let formElement = document.querySelector('.modal__content form.form-input-set');
    let productSku = formElement ? formElement.dataset.productSku : null;

    let debitorId = LocalStorage.getDebitorId();

    const containerRef = useRef(null);

    const handleModalMouseEnter = () => {
        if (containerRef.current) {
            containerRef.current.classList.add('no-underline');
        }
    };

    const handleModalMouseLeave = () => {
        if (containerRef.current) {
            containerRef.current.classList.remove('no-underline');
        }
    };

    useEffect(() => {
        const apiUrl = '/rest/V1/organisation/gpsr/' + manufacturer.manufacturer;

        $.ajax({
            url: apiUrl,
            type: 'GET',
            success: function(data) {
                setData(data);
                setLoading(false);
            },
            error: function(error) {
                console.error('Couldn\'t fetch data. Error:', error);
                setError(error);
                setLoading(false);
            }
        });
    }, []);
console.log(data);
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = (event) => {
        event.stopPropagation();
        setShowModal(false);
        if (containerRef.current) {
            containerRef.current.classList.remove('no-underline');
        }
    };

    return (
        <div className={'infobox__manufacturer'}
            onClick={handleOpenModal}
             style={data.length > 0 && !loading ? {} : { display: 'none' }}>
            <div>
                <span className="paragraph-text__icon reyhericon icon-navigation-right"></span>
                {manufacturer.manufacturer}
            </div>
            {showModal && (
                <div className="infobox__modal"
                     onMouseEnter={handleModalMouseEnter}
                     onMouseLeave={handleModalMouseLeave}>
                    <span className={'infobox__close'} onClick={(event) => handleCloseModal(event)}></span>
                    <h3 className="bottomline">{__("Manufacturer contact details")}</h3>
                    <p></p>
                    <p style={{ margin: 0 }}>{data[2]}</p>
                    <p style={{ margin: 0 }}>{data[3]}</p>
                    <p style={{ margin: 0 }}>{data[7]}</p>
                    <p style={{ margin: 0 }}>{data[6]} {data[5]}</p>
                    <p style={{ marginBottom: "5px" }}>{data[4]}</p>
                    <p style={{ margin: 0 }}>{data[8]}</p>
                    {data[10] && (
                        <div style={{marginTop: "30px"}}>
                            <h3 className="bottomline">{__("Importer")}</h3>
                            <p></p>
                            <p style={{margin: 0}}>{data[10]}</p>
                            <p style={{margin: 0}}>{data[11]}</p>
                            <p style={{margin: 0}}>{data[15]}</p>
                            <p style={{margin: 0}}>{data[14] > 9999 && (data[14])} {data[13]}</p>
                            <p style={{marginBottom: "5px"}}>{data[12]}</p>
                            <p style={{margin: 0}}>{data[16]}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ManufacturerBox;
