import {__} from "../../i18n";

const Loader = () => {
    return (
        <div className="loading-mask" data-role="loader">
            <div className="loader">'
                <img alt={__('Loading...')} src="https://rio.reyher.de.local/static/version1731593826/frontend/reyher/theme_base/de_DE/images/loader-2.gif" />
                    <p>{__('Please wait...')}</p>
                </div>
        </div>
    );
}

export default Loader;
