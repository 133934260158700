import {ianBulkService, ianDeleteService, ianSaveService} from "../services/ian-service";

export async function ianBulkAction(bodyData) {
    return await ianBulkService(bodyData)
}

export async function ianSaveAction(bodyData) {
    return await ianSaveService(bodyData)
}

export async function ianDeleteAction(bodyData, sku) {
    return await ianDeleteService(bodyData, sku)
}
