import {AppContextProvider} from "../../context/AppContextProvider";
import OrderHistoryRow from "./OrderHistoryRow";
import React, {useEffect, useState} from "react";
import LocalStorage from "../../utils/localStorage";
import {__} from "../../i18n";
import {ianBulkAction} from "../../data/actions/ian-action";
import MassActions from "../../components/toolbar/MassActions";

/**
 * @todo shipping address - country?
 *
 * @param order
 * @param orderItems
 * @param quote
 * @param quoteItems
 * @param shippingAddress
 * @returns {JSX.Element}
 * @constructor
 */
const OrderHistoryList = ({order, orderItems, quote, shippingAddress}) => {

    const [hits, setHits] = useState([]);
    const [totals, setTotals] = useState(0);

    const [getMassActionItems, setMassActionItems] = useState([]);
    const [ianList, setIanList] = useState(null);


    const setData = async (result) => {

        setHits(result.hits?.hits);

        setTotals(result.hits?.total?.value);

        setMassActionItems(result.hits?.hits.map(product => (
            {
                _id: product._source.item_id,
                sku: product._source.sku,
                qty: product._source.reyher_ve,
                checked: false
            }
        )));
    }

    useEffect(async () => {

        await setData(orderItems);

        document.addEventListener("legacy-application-ready", () => {
            console.log("legacy-application-ready");

            if (typeof window.TB !== 'undefined') {
                window.TB.findModules(window.jQuery(".js-Modal").parent().get(0));
            }
        })
    }, []);

    const handleOnChange = (index, qty) => {
        let updatedCheckedState = getMassActionItems;

        updatedCheckedState.forEach((product, idx) => {
            if (idx === parseInt(index)) {
                if (typeof qty === "undefined") {
                    product.checked = !product.checked;
                } else {
                    product.qty = qty;
                    product.checked = true;
                }
            }
        });

        setMassActionItems(updatedCheckedState);
    }

    const fetchIan = async () => {

        if (!LocalStorage.checkIfIsLoggedIn()) {
            return;
        }

        const skuList = getMassActionItems.map((item) => {
            return item.sku
        });

        if (skuList.length) {
            const bodyData = {"skus": skuList}

            const response = await ianBulkAction(bodyData);
            setIanList(response);
        }
    }

    useEffect(() => {
        fetchIan();
    }, [getMassActionItems]);

    return (
        <>
            <AppContextProvider getMassActionItems={getMassActionItems}
                                setMassActionItems={setMassActionItems}
                                ianList={ianList}
                                setIanList={setIanList}>

                <div className="row margin-top-20">
                    <div className="d-none d-md-block col-lg-8">

                        {order.supplier_order_id && <div className="row">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Referencenumber:')}</div>
                            <div className="col margin-bottom-0">{order.supplier_order_id}</div>
                        </div>}

                        {quote.sap_ordernumber && <div className="row">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Customer Order Number:')}</div>
                            <div className="col margin-bottom-0">{quote.sap_ordernumber}</div>
                        </div>}

                        {order.order_notice && <div className="row">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Hint:')}</div>
                            <div className="col margin-bottom-0">{order.order_notice}</div>
                        </div>}

                        <div className="row">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Username:')}</div>
                            <div className="col margin-bottom-0">{LocalStorage.getUserName()}</div>
                        </div>

                        <div className="row">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Shipping Address:')}</div>
                            <div className="col margin-bottom-0">
                                {shippingAddress.company}, {shippingAddress.street}, {shippingAddress.postcode}, {shippingAddress.city}
                            </div>
                        </div>

                        {quote.desired_delivery_date && <>
                            <div id="flyout-32">
                                <div className="flyout-reyher__content">
                                    <p className="paragraph-text paragraph-text--small">
                                        {__('For orders before December 31, 2023, desired shipping date instead of desired delivery date')}
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col margin-bottom-0 font-weight-bold">
                                    <div className="col margin-bottom-0 font-weight-bold">
                                        {!LocalStorage.isS4HanaEnabled() ? (
                                            <>
                                                {__('Desired delivery date')}
                                                <div type="button" className="circle-icons js-FlyoutReyher"
                                                     data-target="flyout-32"
                                                     data-dismiss="modal" data-hover="1">
                                                    <span aria-hidden="true" className="circle-icons__information"></span>
                                                </div>
                                            </>
                                        ) : (
                                            __('Requested shipping date:')
                                        )}
                                    </div>
                                </div>
                                <div className="col margin-bottom-0">
                                    {quote.desired_delivery_date}
                                </div>
                            </div>
                        </>}

                    </div>
                    <div className="d-md-none">

                        {order.supplier_order_id && <div className="col-12 margin-bottom-15 padding-left-0">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Referencenumber:')}</div>
                            <div className="col margin-bottom-0">{order.supplier_order_id}</div>
                        </div>}

                        {quote.sap_ordernumber && <div className="col-12 margin-bottom-15 padding-left-0">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Customer Order Number:')}</div>
                            <div className="col margin-bottom-0">{quote.sap_ordernumber}</div>
                        </div>}

                        {order.order_notice && <div className="col-12 margin-bottom-15 padding-left-0">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Hint:')}</div>
                            <div className="col margin-bottom-0">{order.order_notice}</div>
                        </div>}

                        <div className="col-12 margin-bottom-15 padding-left-0">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Username:')}</div>
                            <div className="col margin-bottom-0">{LocalStorage.getUserName()}</div>
                        </div>

                        <div className="col-12 margin-bottom-15 padding-left-0">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Shipping Address:')}</div>
                            <div className="col margin-bottom-0">
                                {shippingAddress.company}, {shippingAddress.street}, {shippingAddress.postcode}, {shippingAddress.city}
                            </div>
                        </div>

                        {quote.desired_delivery_date && <div className="col-12 margin-bottom-15 padding-left-0">
                            <div className="col margin-bottom-0 font-weight-bold">{__('Requested shipping date:')}</div>
                            <div className="col margin-bottom-0">{quote.desired_delivery_date}</div>
                        </div>}
                    </div>
                    <div className="col-4 table-order-toolbar">
                        <MassActions/>
                    </div>
                </div>

                <div className="order-items table-container">
                    <table className="data table table-order-items table-order-items-view cartlist js-Productlist"
                           id="my-orders-table"
                           summary={__('Items Ordered')}>
                        <thead>
                        <tr>
                            <th scope="col">
                                <div className="form-checkbox-set">
                                    <input id="cbr0" type="checkbox" name="headcheckbox" value="0"
                                           className="form-checkbox"/>
                                    <label htmlFor="cbr0"><span className=""></span></label>
                                </div>
                            </th>
                            <th className="col table-order-items_articlenumber">{__('Articlenumber')}</th>
                            <th className="col table-order-items_description">{__('Description')}</th>
                            <th scope="col" className="text-align-right">{__('Quantity/VE')}</th>
                            <th scope="col" className="table-order-items_desquantity">{__('Desired Quantity')}</th>
                            <th scope="col" className="text-align-right">{__('Weight')}</th>
                            <th scope="col" className="text-align-right">{__('Total')}<br/>{__('Price/100')}</th>
                            <th scope="col">{__('custom articlenumber')}<br/>{__('custom Orderposition/-text')}</th>
                            <th scope="col" className="table-order-items_empty"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {hits?.map((orderItem, index) => (
                            <OrderHistoryRow
                                index={index}
                                id={orderItem._id}
                                orderItem={orderItem._source}
                                onChange={handleOnChange}
                            />
                        ))}
                        <tr className="summary display-block-md-down">
                            <td colSpan="6" className="text-align-right bold summary-weight">
                                <table
                                    className="clean float-right text-align-right float-right display-none display-inherit-md-up">
                                    <tbody>
                                    <tr className="summary">
                                        <td>{__('Weight:')}</td>
                                        <td>{order.weight}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan="2" className="summary text-align-right bold">
                                <table className="clean text-align-right float-right">
                                    <tbody>
                                    <tr className="summary display-none display-inherit-sm-down">
                                        <td>{__('Weight:')}</td>
                                        <td>{order.weight}</td>
                                    </tr>
                                    <tr className="summary">
                                        <td>{__('Subtotal:')}</td>
                                        <td>{order.subtotal}</td>
                                    </tr>

                                    {order.has_energy_surcharge && <tr className="summary">
                                        <td>
                                            {__('Prop. freight costs (does not apply for self-pickup):')}
                                        </td>
                                        <td>
                                            <span>{order.energy_surcharge}</span>
                                        </td>
                                    </tr>}

                                    {order.has_discount && <tr className="summary">
                                        <td>
                                            discount
                                        </td>
                                        <td>
                                            <span>{order.discount}</span>
                                        </td>
                                    </tr>}

                                    <tr className="summary">
                                        <td>{__('Total:')}</td>
                                        <td>{order.grand_total}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan="1"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </AppContextProvider>
        </>
    );
}

export default OrderHistoryList;
