import {AppContextProvider} from "../../../context/AppContextProvider";
import RfqCartRow from "./RfqCartRow";
import {useEffect, useState} from "react";
import {getIdFromCurrentQuoteAction} from "../../../data/actions/rfq-action";
import LocalStorage from "../../../utils/localStorage";

const RfqCart = ({product, quoteItemId}) => {

    const [customerCartId, setCustomerCartId] = useState(null);
    const [getMassActionItems, setMassActionItems] = useState([]);
    const [ianList, setIanList] = useState(null);


    const fetchRfqCart = async () => {

        if (!LocalStorage.checkIfIsLoggedIn()) {
            return;
        }

        let cartId = await getIdFromCurrentQuoteAction();
        setCustomerCartId(cartId);
    }

    // load customer card
    useEffect(() => {
        fetchRfqCart();
    }, []);

    return (
        <>
            <AppContextProvider customerCartId={customerCartId}
                                setCustomerCartId={setCustomerCartId}
                                getMassActionItems={getMassActionItems}
                                setMassActionItems={setMassActionItems}
                                ianList={ianList}
                                setIanList={setIanList}>
                <RfqCartRow product={product} quoteItemId={quoteItemId}/>
            </AppContextProvider>
        </>
    );
}

export default RfqCart;
