import React, {useState} from 'react';
import Amount from "../../../components/toolbar/Amount";
import Pagination from "../../../components/toolbar/Pagination";
import {ToolbarContext} from "../../../context/ToolbarContext";
import UrlParsing from "../../../utils/urlParsing";
import MassActionsNew from "../../../components/toolbar/MassActionsNew";
import {__} from "../../../i18n";
import {useAddToCart} from "../../../hooks/useAddToCart";
import {useAddToRfqCart} from "../../../hooks/useAddToRfqCart";
import {useRequestCad} from "../../../hooks/useRequestCad";
import {useRemoveFromWishlist} from "../../../hooks/useRemoveFromWishlist";
import {useUpdateItemQuantityInWishlist} from "../../../hooks/useUpdateItemQuantityInWishlist";
import {useMoveItemsToWishlist} from "../../../hooks/useMoveItemsToWishlist";
import LocalStorage from "../../../utils/localStorage";
import {createLabelAction} from "../../../data/actions/create-label-action";

const Toolbar = ({totals = null, currentLimit= null, currentPage= null}) => {
    //@todo do not work without parameter in url
    const limit = UrlParsing().getProductListLimit();
    const [pageSize, setPageSize] = useState(limit);

    const addToCart = useAddToCart();
    const addToRfqCart = useAddToRfqCart();
    const requestCad = useRequestCad();
    const removeFromWishlist = useRemoveFromWishlist();
    const updateItemQuantityInWishlist = useUpdateItemQuantityInWishlist();
    const moveItemsToWishlist = useMoveItemsToWishlist();

    const carts = LocalStorage.getAllCarts();
    const wishlists = LocalStorage.getWishlists();

    const hasMultipleCarts = carts?.length > 1;
    const hasMultipleWishlists = wishlists?.length > 1

    const availableActions = [
        {id:0, value: "addToCart", label: __("add to cart"), onClick: () => addToCart(), hasHover: hasMultipleCarts, hoverOptions: carts, flyoutHeader: __("choose cart")},
        {id:1, value: "addToRfqCart", label: __("add to request cart"), onClick: () => addToRfqCart()},
        {id:3, value: "requestCad", label: __("request cad"), onClick: () => requestCad()},
        {id:4, value: "createLabel", label: __("create label"), onClick: () => createLabelAction()},
        {id:5, value: "removeFromWishlist", label: __("remove from favorites"), onClick: () => removeFromWishlist()},
        {id:6, value: "updateItemQuantityInWishlist", label: __("update quantity"), onClick: () => updateItemQuantityInWishlist()},
        {id:7, value: "moveItemsToWishlist", label: __("move products"), onClick: () => moveItemsToWishlist(), hasHover: hasMultipleWishlists, hoverOptions: wishlists, flyoutHeader: __("Favourite list")},
    ];

    return (
        <div className="productlist_toolbar productlist_toolbar--top">
            <ToolbarContext.Provider value={{limit, pageSize, setPageSize}} >
                <Amount currentLimit={currentLimit}/>
                <Pagination totals={totals} currentPage={currentPage}/>
                <MassActionsNew options={availableActions}/>
            </ToolbarContext.Provider>
        </div>
    );
}

export default Toolbar;
