import React, { useState } from "react";
import {__} from "../../i18n";
import FlyoutComponent from "../button/FlyoutComponent";
import {useFlyout} from "../button/useFlyout";
import useOutsideClick from "../../hooks/useOutsideClick";

const MassActionsNew = ({options}) => {
    const [selected, setSelected] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredOption, setHoveredOption] = useState(null);

    const { isHovered, position, buttonRef, flyoutRef, handleMouseEnter } = useFlyout();

    const handleClickOutside = () => {
        setIsOpen(false);
    }

    const dropDownRef = useOutsideClick(handleClickOutside);

    const onMouseEnterHandler = (option) => {
        setHoveredOption(option.value);
        handleMouseEnter();
    }

    return (
        <div className="productlist_toolbar_actions">
            <div className={`dropdown-reyher dropdown-reyher--link-button ${isOpen ? 'dropdown-reyher--open' : ''}`}
                 ref={dropDownRef}
            >

                <a onClick={() => setIsOpen(!isOpen)}
                        className="dropdown-reyher__toggle">
                    {selected ? options.find(opt => opt.value === selected)?.label : __("Choose action")}
                </a>

                <div className="dropdown-reyher__button-container">
                    {options.map((option) => (selected === option.value) && (
                        <>
                            <button
                                key={option.value}
                                onClick={option.onClick}
                                disabled={selected !== option.value}
                                className={`button-reyher button-reyher--primary button-reyher--square`}
                                onMouseEnter={() => onMouseEnterHandler(option)}
                                //onMouseLeave={() => setHoveredOption(null)}
                                ref={buttonRef}
                            >
                                <span className="button-reyher__link">
                                    <span className="reyhericon icon-arrow"></span>
                                </span>
                            </button>

                            { hoveredOption === option.value && option.hasHover && (
                                <FlyoutComponent
                                    position={position}
                                    dropdownItems={option.hoverOptions}
                                    header={option.flyoutHeader}
                                    activeProp={"is_active"}
                                    buttonRef={buttonRef}
                                />
                            )}
                        </>
                    ))}

                    {(selected === null) && (<button type="button"
                            className={`button-reyher button-reyher--primary button-reyher--square`}>
                        <span className="button-reyher__link">
                            <span className="reyhericon icon-arrow"></span>
                        </span>
                    </button>)}
                </div>

                {isOpen && (
                    <ul className="dropdown-reyher__list dropdown-reyher__content">
                        {options.map((option) => (
                            <li key={option.value}
                                className="dropdown-reyher__item"
                                onClick={() => {
                                    setSelected(option.value);
                                    setIsOpen(false);
                                }}
                            >
                                <a className="dropdown-reyher__link">{option.label}</a>
                            </li>
                        ))}
                    </ul>
                )}

            </div>
        </div>
    );
}

export default MassActionsNew;
