export async function openDetailLayerService(product, event) {
    try {
        const BASE_URL = window.BASE_URL || window.location.origin;

        const apiUrl = BASE_URL + "/" + product.sku + ".html";

        return await fetch(apiUrl, {
            method: "GET",
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            }).then((content) => {
                let container = (window.jQuery(content).filter('.content').find('.container-reyher'));
                /**
                 * create modal and add content
                 */
                let ev = new CustomEvent("open-modal", {'detail': {
                        title: product.name,
                        content: container[0],
                        button: event.target
                    }});

                document.dispatchEvent(ev);

                return true;

            })
            .catch((error) => {
                console.error("Error:", error);
            });
    } catch (e) {
        console.error("Cart Service Error:", e);
        throw e;
    }
}
