import {AppContextProvider} from "../../../context/AppContextProvider";
import OfferCartRow from "./OfferCartRow";
import React, {useEffect, useState} from "react";
import {__} from "../../../i18n";
import Toolbar from "./toolbar/Toolbar";

/**
 * todo hide/show request
 */
const OfferCart = ({offer, offerItems, hasItemsOfExpiredOffers = true}) => {
    const [hits, setHits] = useState([]);
    const [totals, setTotals] = useState(0);

    const [getMassActionItems, setMassActionItems] = useState([]);
    const [ianList, setIanList] = useState(null);

    const setData = async (result) => {

        setHits(result.hits?.hits);

        setTotals(result.hits?.total?.value);

        setMassActionItems(result.hits?.hits.map(product => (
            {
                _id: product._source.item_id,
                sku: product._source.sku,
                qty: product._source.reyher_ve,
                checked: true
            }
        )));
    }

    useEffect(async () => {
        await setData(offerItems);

        document.addEventListener("legacy-application-ready", () => {
            console.log("legacy-application-ready");

            if (typeof window.TB !== 'undefined') {
                window.TB.findModules(window.jQuery(".js-Modal").parent().get(0));
            }
        })
    }, []);

    const handleOnChange = (index, qty) => {
        let updatedCheckedState = getMassActionItems;

        updatedCheckedState.forEach((product, idx) => {
            if (idx === parseInt(index)) {
                if (typeof qty === "undefined") {
                    product.checked = !product.checked;
                } else {
                    product.qty = qty;
                    product.checked = true;
                }
            }
        });

        setMassActionItems(updatedCheckedState);
    }

    const toggleAllCheckboxes = () => {
        let updatedCheckedState = getMassActionItems;

        updatedCheckedState.forEach((product, idx) => {
            product.checked = true;
        });

        setMassActionItems(updatedCheckedState);
    }

    return (
        <>
            <AppContextProvider getMassActionItems={getMassActionItems}
                                setMassActionItems={setMassActionItems}
                                ianList={ianList}
                                setIanList={setIanList}>

                <div className="offer-view">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 offer-view__reference margin-bottom-10">
                            <div className="row margin-bottom-10">
                                <div className="col margin-bottom-0">{__('SAP Offer Id:')}</div>
                                <div className="col margin-bottom-0">{offer.offer_id}</div>
                            </div>
                            <div className="row margin-bottom-10">
                                <div className="col margin-bottom-0">{__('RIO-Requestnumber:')}</div>
                                <div className="col margin-bottom-0">{offer.sap_rfq_id}</div>
                            </div>

                            {offer.sap_ordernumber &&
                                <div className="row margin-bottom-10">
                                    <div className="col margin-bottom-0">{__('Customer Request Number')}</div>
                                    <div className="col margin-bottom-0">{offer.sap_ordernumber}</div>
                                </div>
                            }

                            <div className="row margin-bottom-10">
                                <div className="col margin-bottom-0">{__('Offer valid until:')}</div>
                                <div className="col margin-bottom-0">{offer.date_of_expiry}</div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 margin-bottom-0">
                            <div className="row offer-view__status d-lg-none">
                                <div className="col margin-bottom-10 d-block">{__('Request status:')}</div>
                                <div className="col d-block">
                                    <span>{offer.is_offer_hidden ? __('hidden') : __('shown')}</span>
                                    <a href="#"
                                       className="button-reyher button-reyher--secondary pull-right">
                                        <span className="button-reyher__link">
                                            {offer.is_offer_hidden ? __('show') : __('hidde')}
                                        </span>
                                    </a>
                                </div>
                            </div>

                            <div className="row offer-view__status quote-view__status-lg">
                                <div className="offer-view__status-request">{__('Request status:')}</div>
                                <div className="offer-view__status-visibility">
                                    <p className="margin-right-10">{offer.is_offer_hidden ? __('hidden') : __('shown')}</p>
                                    <a href="#"
                                       className="button-reyher button-reyher--secondary pull-right">
                                        <span className="button-reyher__link">
                                            {offer.is_offer_hidden ? __('show') : __('hidde')}
                                        </span>
                                    </a>
                                </div>
                            </div>


                            {offer.hint_internal &&
                                <div className="row">
                                    <div className="col margin-bottom-0 c-error">{__('Notice')}</div>
                                    <div className="col margin-bottom-0 c-error">{offer.hint_internal}</div>
                                </div>
                            }

                            {offer.hint_second &&
                                <div className="row">
                                    <div className="col margin-bottom-0 c-error">{__('Notice 2')}</div>
                                    <div className="col margin-bottom-0 c-error">{offer.hint_second}</div>
                                </div>
                            }

                            {offer.hint_delivery &&
                                <div className="row">
                                    <div className="col margin-bottom-0 c-error">{__('Delivery time')}</div>
                                    <div className="col margin-bottom-0 c-error">{offer.hint_delivery}</div>
                                </div>
                            }

                            {offer.hint_price &&
                                <div className="row">
                                    <div className="col margin-bottom-0 c-error">{__('Price set')}</div>
                                    <div className="col margin-bottom-0 c-error">{offer.hint_price}</div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-0 col-md-7 col-lg-8 padding-0"></div>
                    <div id="rfq-view-toolbar" className="col-sm-12 col-md-5 col-lg-4">
                        <Toolbar hasItemsOfExpiredOffers={hasItemsOfExpiredOffers} />
                    </div>
                </div>

                <div className="table-container cart-container offer-cart-container">
                    <div className="form-input-set">
                        <table className="table cartlist_table offer-table js-Productlist">
                            <thead>
                            <tr>
                                <th scope="col">
                                    <div className="form-checkbox-set">
                                        <input id="cbr0" type="checkbox" name="headcheckbox" value="1"
                                               className="form-checkbox"
                                               onChange={() => toggleAllCheckboxes()}/>
                                        <label htmlFor="cbr0">
                                            <span className=""></span>
                                        </label>
                                    </div>
                                </th>
                                <th scope="col">{__('Articlenumber')}<br/>{__('Description')}</th>
                                <th scope="col"
                                    className="text-align-right">{__('Quantity/VE')}<br/>{__('Positionnumber')}
                                </th>
                                <th scope="col"
                                    className="offer-table_quanity">{__('Quantity')}<br/>{__('Delivery Note')}
                                </th>
                                <th scope="col" className="text-align-right">{__('Total')}<br/>{__('Price/100')}
                                </th>
                                <th scope="col" className="text-align-right">{__('Weight Total')}</th>
                                <th scope="col"
                                    className="offer-table_articlenumber">{__('custom articlenumber')}</th>
                                <th scope="col" className="offer-table_status">{__('Status')}</th>
                                <th scope="col" className="offer-table_empty">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                                {hits?.map((offerItem, index) => (
                                    <OfferCartRow index={index}
                                                  id={offerItem._id}
                                                  offerItem={offerItem._source}
                                                  onChange={handleOnChange}
                                    />
                                ))}
                                <tr className={` ${totals % 2 ? "even" : "odd"} summary`}>
                                    <td colSpan="9" className="text-align-right offer-view-total">
                                        <div>
                                            <span className="bold">{__('Subtotal:')}</span>
                                            <span>&nbsp;{offer.calculated_subtotal}</span>
                                        </div>

                                        {offer.has_order_discount &&
                                            <div>
                                            <span>{offer.invoice_discount}</span>
                                            <span>&nbsp;{offer.order_discount}</span>
                                        </div>
                                        }

                                        {offer.has_yezu &&
                                            <div>
                                                <span>{__('Prop. freight costs (does not apply for self-pickup):')}</span>
                                                <span>&nbsp;{offer.yezu}</span>
                                            </div>
                                        }

                                        <div>
                                            <span className="bold">{__('Grand Total:')}</span>
                                            <span>&nbsp;{offer.calculated_grand_total}</span>
                                        </div>

                                        <div>
                                            <span className="bold">{__('Weight:')}</span>
                                            <span>&nbsp;{offer.calculated_weight}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </AppContextProvider>
        </>
    );
}

export default OfferCart;
