import {__} from "../i18n";
import {moveWishlistAction} from "../data/actions/wishlist-action";
import {useContext} from "react";
import {MassDataContext} from "../context/MassDataContext";
import {MessageContext} from "../context/MessageContext";
import LocalStorage from "../utils/localStorage";

export const useMoveItemsToWishlist = () => {

    const {getMassActionItems, setSuccessfullyAddedProductsToWishlist} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);
    const BASE_URL = window.location.origin || window.BASE_URL;


    return async() => {
        console.log("call wishlist move");
        const activeWishlist = LocalStorage.getWishlist();


        const bodyData = {
            wishlistItems: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                    item_id: item._id
                }))
        };

        if (!bodyData.wishlistItems.length) {
            setErrorMessage(__("no products selected"));
            return;
        }

        //@todo wishlist id
        const response = await moveWishlistAction(bodyData, activeWishlist.additional.current_wishlist_id);

        response.forEach((el) => {
            if (el?.success) {
                setSuccessMessage(__("Product successfully added to wishlist"));
                window.location = BASE_URL + "/" + el.redirect;
            }

            if (el?.success === false) {
                setErrorMessage(__('Product not successfully added to wishlist'));
            }
        });
    }
}
