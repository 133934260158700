import {__} from "../i18n";
import {addProductsToWishlistAction} from "../data/actions/wishlist-action";
import {useContext} from "react";
import {MassDataContext} from "../context/MassDataContext";
import {MessageContext} from "../context/MessageContext";

export const useAddToWishlist = () => {

    const {getMassActionItems, setSuccessfullyAddedProductsToWishlist,} = useContext(MassDataContext);
    const {setErrorMessage, setSuccessMessage} = useContext(MessageContext);

    return async() => {
        const bodyData = {
            products: getMassActionItems
                .filter(item => item.checked) // Include only items with checked === true
                .map(item => ({
                    sku: item.sku,
                    qty: parseInt(item.qty, 10), // Ensure qty is a number
                }))
        };

        if (!bodyData.products.length) {
            setErrorMessage(__("no products selected"));
            return;
        }

        const response = await addProductsToWishlistAction(bodyData);

        response.forEach((el) => {
            if (el?.successfullyAdded?.length) {
                setSuccessfullyAddedProductsToWishlist(el.successfullyAdded);
            }

            if (el?.messages?.success?.length) {
                setSuccessMessage(__(el.messages.success[0]));
            }

            if (el?.messages?.error?.length) {
                setErrorMessage(__(el.messages.error[0]));
            }
        });
    }
}
